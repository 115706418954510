export const $get = key => {
  const v = localStorage.getItem(key);
  if (!v) return;
  try {
    return JSON.parse(v);
  } catch (e) {
    return v;
  }
};

export const $set = (key, value) => localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);

export const $remove = key => localStorage.removeItem(key);
