import {conn} from './init';
import websdk from 'easemob-websdk';
import {config as webimConfig} from './config';
import i18n from '@/locale';
import store from '@/store';
import {randomLowerLetter} from './tools';
import {getAjaxResumeGet, postGetJob, postGetResume} from '@/services';
import {$get, $remove, $set} from '@/store/ls';
import {getHistoryMessages_admin} from "@/assets/js/easemob/admin";

export const fomatter = {
    TEXT: (d, o) => {
        d.data = o.data.replace(/\n/g, '<br />');
    },
    IMAGE: (d, o) => {
        d.url = o.url;
    },
    VOICE: () => {
    },
    CUSTOM: (d, o, isNewMsg) => {
        d.customEvent = o.customEvent;
        d.from = o.from;
        d.customExts = o.customExts;
        if (['swapTelegram', 'modifyInterviewTime', 'inviteInterview', 'applyInterview'].includes(d.customEvent)) {
            if (isNewMsg) {
                d.customExts.disabled = false;
                d.id = o.id;
            } else {
                d.id = o.id;
            }
        }
    },
    COMMAND(d, o) {
        console.log(o);
        d.data = o;
    },
};

// ack 指定 group 消息
export const ackChannelMsg = to => {
    const msg = new websdk.message('channel', conn.getUniqueId());
    if (to.length < 10) {
        msg.set({to});
    } else {
        msg.set({to, chatType: 'groupChat'});
    }
    return conn.send(msg.body);
};

// ack 管理员消息
export const ackManagerMsg = to => {
    console.log('ack 管理员消息')
    const msg = new websdk.message('channel', conn.getUniqueId());
    msg.set({to});
    return conn.send(msg.body);
};

/**
 * 发送文本消息，非管理员
 * @param groupid
 * @param text
 * @returns {Promise<EasemobChat.SendMsgResult>}
 */
export const sendTextMsg = (groupid, text) => {
    console.log('发送文本消息', groupid, text)
    if(groupid!='363440-1'||groupid!='2176-1'){
        console.log('用户发给其他人')
        const msg = new websdk.message('txt', conn.getUniqueId());
        msg.set({
            msg: text,
            to: groupid,
            chatType: 'groupChat',
            ext: {
                em_apns_ext: {
                    em_push_name: store.state.me.nickname,
                    em_push_content: text,
                },
            },
            success(e) {
                store.dispatch('addMsg', {
                    contentsType: 'TEXT',
                    data: text,
                    to: groupid,
                    from: store.state.me.user,
                });
            },
            fail(e) {
                console.log({
                    message: e.message || i18n.t('发送失败'),
                    type: 'error',
                });
                console.error(e);
            },
        });
        return conn.send(msg.body);
    }else {
        console.log('用户发给管理员')
    }

};

/**
 * 管理员发送文本消息
 */
export function sendTextMsg_admin(text) {
    console.log('管理员发送消息到用户', text)
    console.log(store.state.target_admin, '管理员需要发送的对象')
    let obj = store.state.target_admin
    let option = {
        // 设置消息类型。
        type: "txt",
        // 设置消息内容。
        msg: text,
        // 设置消息接收方，单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
        // to: obj.lastMessage.from,
        to: obj.lastMessage.from == store.state.admin1.uid ? obj.lastMessage.to : obj.lastMessage.from,
        // 设置会话类型，单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`，默认为单聊。
        chatType: "singleChat",
    };
    // 创建文本消息。
    let msg = WebIM.message.create(option);
    // 调用 `send` 方法发送该文本消息。
    conn.send(msg).then((res)=>{
        console.log(res, '管理员发送消息成功')
        getHistoryMessages_admin(obj)
    }).catch((err)=>{
        console.log(err, '管理员发送消息失败')
    });
};

/**
 * 向管理员发送消息
 * @param text
 * @returns {Promise<EasemobChat.SendMsgResult>}
 */
export const sendTextMsgToAdmin = text => {
    const {uid} = store.state.admin;
    console.log('向管理员发送消息', uid)
    const msg = new websdk.message('txt', conn.getUniqueId());
    msg.set({
        msg: text,
        to: uid,
        chatType: 'singleChat',
        success(e) {
            console.log('向管理员发送消息成功')
            store.state.groupMsg[uid].push({
                contentsType: 'TEXT',
                data: text,
                isLeft: false,
            });
            store.state.reloadIdx++;
            console.log(store.state.groupMsg)
            console.log(store.state.reloadIdx)
        },
        fail(e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log('向管理员发送消息失败')
        },
    });
    return conn.send(msg.body);
};

export const sendImgMsgToAdmin = fileDom => {
    const {uid} = store.state.admin;
    const msg = new websdk.message('img', conn.getUniqueId());
    const file = websdk.utils.getFileUrl(fileDom);
    msg.set({
        file,
        to: uid,
        apiUrl: webimConfig.apiURL,
        chatType: 'singleChat',
        onFileUploadComplete: data => {
            // 消息上传成功

            const url = data.uri + '/' + data.entities[0].uuid;
            store.state.groupMsg[uid].push({
                url,
                contentsType: 'IMAGE',
                isLeft: false,
            });
            store.state.reloadIdx++;
        },
        fail(e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.error(e);
        },
    });
    return conn.send(msg.body);
};

const allowType = {
    jpg: true,
    gif: true,
    png: true,
    bmp: true,
};

// 发送图片消息
export const sendImgMsg = (groupid, fileDom) => {
    const msg = new websdk.message('img', conn.getUniqueId());
    const file = websdk.utils.getFileUrl(fileDom);
    if (!file.filename) return;
    if (!allowType[file.filetype.toLowerCase()])
        return console.log({
            message: i18n.t('格式不支持'),
            type: 'error',
        });
    msg.set({
        file,
        to: groupid,
        apiUrl: webimConfig.apiURL,
        ext: {
            em_apns_ext: {
                em_push_name: store.state.me.nickname,
                em_push_content: '[image]',
            },
        },
        // 接收消息对象
        roomType: false, // 判断是否为聊天室
        chatType: 'groupchat',
        onFileUploadError: res => {
            console.log({
                message: i18n.t('发送失败'),
                type: 'error',
            });
        },
        onFileUploadComplete: data => {
            // 消息上传成功
            store.dispatch('addMsg', {
                contentsType: 'IMAGE',
                to: groupid,
                from: store.state.me.user,
                url: data.uri + '/' + data.entities[0].uuid,
            });
        },
    });
    return conn.send(msg.body);
};

// 发送职位卡片消息
export const sendJobCardMsg = (groupid, customExts) => {
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'jobInfo', // 创建自定义事件
        customExts, // 消息内容，key/value 需要 string 类型,
        ext: {
            em_apns_ext: {em_push_name: store.state.me.nickname, em_push_content: i18n.t('[职位卡片]')},
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: async function () {
            store.dispatch('addMsg', {
                contentsType: 'CUSTOM',
                to: groupid,
                from: store.state.me.user,
                customEvent: 'jobInfo',
                customExts,
            });
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// customExts  {nickname:"",telegram:"",email:"", wechat:"", phone:""}
export const sendGetInTouchMsg = customExts => {
    const {currentGroup: groupid, groupUserMap} = store.state;
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'getInTouch', // 创建自定义事件
        customExts, // 消息内容，key/value 需要 string 类型,
        ext: {
            em_apns_ext: {em_push_name: store.state.me.nickname, em_push_content: i18n.t('[联系方式]')},
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: async function () {
            const {uid, resume_id} = groupUserMap[groupid] || {};
            if (uid) {
                const downloadPage = await getAjaxResumeGet(resume_id, uid);
                store.commit('updateState', {downloadPage});
            }
            store.dispatch('addMsg', {
                contentsType: 'CUSTOM',
                to: groupid,
                from: store.state.me.user,
                customEvent: 'getInTouch',
                customExts,
            });
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

window.sendGetInTouchMsg = sendGetInTouchMsg;

// 发送交换tg消息
export const sendSwapTgMsg = (groupid, telegram) => {
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'swapTelegram', // 创建自定义事件
        customExts: {accepted: '0', deviceId: 'web', telegram}, // 消息内容，key/value 需要 string 类型,
        ext: {
            em_apns_ext: {em_push_name: store.state.me.nickname, em_push_content: i18n.t('[互换Telegram]')},
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function () {
            store.state.groupMsg[groupid].push({
                contentsType: 'CUSTOM',
                isLeft: false,
                customExts: {accepted: '0', deviceId: 'web', telegram}, // 消息内容，key/value 需要 string 类型,
                customEvent: 'swapTelegram',
            });
            store.state.reloadIdx++;
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// 发送 tg 消息
export const sendTgMsg = (groupid, telegram, sended, deviceId, originMsg) => {
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'sendTelegram', // 创建自定义事件
        customExts: {
            telegram,
            sended,
            name: store.state.me.nickname,
            deviceId,
        }, // 消息内容，key/value 需要 string 类型,
        ext: {
            em_apns_ext: {
                em_push_name: store.state.me.nickname,
                em_push_content: '[Telegram]',
            },
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success() {
            const operativeMsg = $get('IM_OPERATIVE_MSG') || {};
            operativeMsg[originMsg.id] = '1';
            store.state.msgStatus[originMsg.id] = '1';
            $set('IM_OPERATIVE_MSG', operativeMsg);
            store.state.groupMsg[groupid].push({
                contentsType: 'CENTER',
                value: i18n.t('已发送Telegram号'),
            });
            store.state.reloadIdx++;
        },
        fail(e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            reject(e);
        },
    });
    return conn.send(msg.body);
};

// 发送面试邀请消息
export const sendInviteInterviewMsg = (groupid, exts) => {
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'inviteInterview', // 创建自定义事件
        customExts: exts,
        ext: {
            em_apns_ext: {em_push_name: store.state.me.nickname, em_push_content: i18n.t('[邀请面试]')},
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
            store.state.groupMsg[groupid].push({
                contentsType: 'CENTER',
                value: i18n.t('您向对方发送了面试邀请'),
            });
            store.state.reloadIdx++;
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// 发送反馈消息
// accepted: 1 接受 2 拒绝
export const sendAcceptMsg = (groupid, originMsg, accepted) => {
    const {customExts, customEvent} = originMsg;
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'acceptedEvent', // 创建自定义事件
        customExts: {...customExts, acceptEvent: customEvent, accepted},
        ext: {
            em_apns_ext: {em_push_name: store.state.me.nickname, em_push_content: i18n.t('[邀请面试]')},
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
            const operativeMsg = $get('IM_OPERATIVE_MSG') || {};
            operativeMsg[originMsg.id] = accepted;
            store.state.msgStatus[originMsg.id] = accepted;
            $set('IM_OPERATIVE_MSG', operativeMsg);
            let msg = '';
            if (accepted === '1') {
                if (customEvent === 'modifyInterviewTime') {
                    msg = i18n.t('您已接受对方修改面试时间');
                }
                if (customEvent === 'applyInterview') {
                    msg = i18n.t('您已同意面试邀请');
                }
                if (customEvent === 'inviteInterview') {
                    const time = new Date(Number(customExts.interviewTime) * 1000);
                    const duration = time - Date.now();
                    const d = Math.floor(duration / (1000 * 60 * 60 * 24));
                    const h = Math.floor(duration / (1000 * 60 * 60));
                    msg = i18n.t('您已同意对方的面试邀请', {d, h});
                }
            } else if (accepted === '2') {
                if (customEvent === 'modifyInterviewTime') {
                    msg = i18n.t('你已拒绝对方修改面试时间');
                }
                if (customEvent === 'sendTelegram' || customEvent === 'swapTelegram') {
                    msg = i18n.t('您已拒绝与对方互换Telegram');
                }
                if (customEvent === 'applyInterview') {
                    msg = i18n.t('你已拒绝视频面试邀请');
                }
                if (customEvent === 'inviteInterview') {
                    msg = i18n.t('您已拒绝对方的面试邀请');
                }
            }

            function jujue() {
                console.log('拒绝')
            }

            store.state.groupMsg[groupid].push({
                contentsType: 'CENTER',
                value: msg,
            });
            store.state.reloadIdx++;
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// 发送简历消息
export const sendResumeMsg = (groupid, username, resumeId) => {
    console.log('发送简历消息',groupid, username, resumeId)
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'sendResume', // 创建自定义事件
        customExts: {username, resumeId},
        ext: {
            em_apns_ext: {em_push_name: store.state.me.nickname, em_push_content: i18n.t('[简历]')},
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
            store.state.groupMsg[groupid].push({
                contentsType: 'CUSTOM',
                customEvent: 'sendResume',
                customExts: {username, resumeId},
            });
            store.state.reloadIdx++;
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// 发送面试申请
export const sendApplyInterviewMsg = groupid => {
    console.log(groupid)
    const msg = new websdk.message('custom', conn.getUniqueId());
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'applyInterview', // 创建自定义事件
        customExts: {accepted: '0'},
        ext: {
            em_apns_ext: {em_push_name: store.state.me.nickname, em_push_content: i18n.t('[申请面试]')},
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
            store.state.groupMsg[groupid].push({
                contentsType: 'CUSTOM',
                customEvent: 'applyInterview',
                customExts: {accepted: '0'},
            });
            store.state.reloadIdx++;
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// 1. 发送视频邀请(企业端发送)
export const sendInviteVideoMsg = async (groupid, uid) => {
    const msg = new websdk.message('custom', conn.getUniqueId());
    const {me, currentJob, currentResume, groupUserMap} = store.state;
    const channelName = randomLowerLetter(10);
    // 求职者Id
    const userId = uid ? uid : groupUserMap[groupid].uid + '-2';
    const callId = randomLowerLetter(10);
    const ext = {
        action: 'invite',
        callId,
        callerDevId: conn.clientResource,
        channelName,
        em_apns_ext: {em_push_name: me.nickname, em_push_content: i18n.t('[视频面试邀请]')},
        ext: {
            jobId: Number(currentJob.id),
            jobName: currentJob.jobs_name,
            members: JSON.stringify([
                {
                    userId,
                    groupId: groupid,
                    resumeId: currentResume.id,
                },
            ]),
        },
        msgType: 'rtcCallWithAgora',
        ts: Date.now(),
        type: 2,
    };
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'inviteVideo', // 创建自定义事件
        customExts: {},
        ext, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    localStorage.setItem(
        'IM_VIDEO_DATA',
        JSON.stringify({
            resume: currentResume,
            job: currentJob,
            groupid,
            uid: userId,
            ext: {channelName, callerDevId: conn.clientResource, callId},
            user: store.state.userInfoMap[userId] || {},
        })
    );
    window.open('/chatapp/video?v=1', 'interview_page');
    return conn.send(msg.body);
};

export const sendInviteVideoMsgByConfig = async (groupid, config) => {
    const msg = new websdk.message('custom', conn.getUniqueId());
    const {jobName, jobsId, resume_id, boss, talent, isBoss} = config;
    const {me} = store.state;
    const channelName = randomLowerLetter(10);
    const callId = randomLowerLetter(10);
    const userId = isBoss ? talent + '-2' : boss + '-1';
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'inviteVideo', // 创建自定义事件
        customExts: {},
        ext: {
            action: 'invite',
            callId,
            callerDevId: conn.clientResource,
            channelName,
            em_apns_ext: {em_push_name: me.nickname, em_push_content: i18n.t('[视频面试邀请]')},
            ext: {
                jobId: jobsId,
                jobName,
                members: JSON.stringify([
                    {
                        userId,
                        groupId: groupid,
                        resume_id,
                    },
                ]),
            },
            msgType: 'rtcCallWithAgora',
            ts: Date.now(),
            type: 2,
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    const [resume, job] = await Promise.all([postGetJob({job_id: jobsId}), postGetResume({resume_id: resume_id})]);
    localStorage.setItem(
        'IM_VIDEO_DATA',
        JSON.stringify({
            resume,
            job,
            groupid,
            uid: userId,
            ext: {callId, channelName, callerDevId: conn.clientResource},
            user: store.state.userInfoMap[userId] || {},
        })
    );
    return conn.send(msg.body);
};

// 1.1 取消视频邀请(企业端发送)
// action: cancelRing/timeout 取消视频通话
export const sendCancelVideoMsg = async (groupid, ext, action) => {
    const msg = new websdk.message('cmd', conn.getUniqueId());
    const {callId, callerDevId} = ext;
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        action: 'rtcCall',
        ext: {
            action,
            callId,
            callerDevId,
            msgType: 'rtcCallWithAgora',
            ts: Date.now(),
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    sendVideoEventMsg(groupid, '', '4');
    return conn.send(msg.body);
};

// 2. 视频邀请ack(求职端发送)
export const sendAckVideoMsg = (groupid, ext) => {
    const msg = new websdk.message('cmd', conn.getUniqueId());
    const {callId, callerDevId} = ext;
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        action: 'rtcCall',
        ext: {
            action: 'alert',
            callId,
            callerDevId,
            calleeDevId: conn.clientResource,
            msgType: 'rtcCallWithAgora',
            ts: Date.now(),
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// 3. 视频邀请响铃 confirmRing(企业端发送)
export const sendConfirmRingMsg = (groupid, ext) => {
    const msg = new websdk.message('cmd', conn.getUniqueId());
    const {callId, callerDevId, calleeDevId} = ext;
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        action: 'rtcCall',
        ext: {
            action: 'confirmRing',
            callId,
            callerDevId,
            calleeDevId,
            msgType: 'rtcCallWithAgora',
            status: true,
            ts: Date.now(),
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    console.log('sendConfirmRingMsg', msg.body);
    return conn.send(msg.body);
};

// 4. 视频邀请回应 answerCall(求职端发送)
// result: accept/refuse
export const sendAnswerCallMsg = (groupid, ext, result) => {
    const msg = new websdk.message('cmd', conn.getUniqueId());
    const {callId = '', callerDevId = '', calleeDevId = ''} = ext;
    console.log('2发送answercall...', ext);
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        action: 'rtcCall',
        ext: {
            action: 'answerCall',
            callId,
            callerDevId,
            calleeDevId,
            msgType: 'rtcCallWithAgora',
            result,
            ts: Date.now(),
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
            console.log('3发送answercall...');
            if (result === 'refuse') {
                $remove('IM_HAS_CALL');
                store.state.groupMsg[groupid].push({
                    contentsType: 'CALL',
                    isLeft: false,
                    data: `<img src='${require('@/assets/img/hang up_icon@2x.png')}' /> ${i18n.t('已拒绝')} `,
                });
                store.state.reloadIdx++;
                sendVideoEventMsg(groupid, '', '2');
            }
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};

// 5. 视频通话结束 confirmCallee1(任意端发送)
export const sendConfirmCalleeMsg = (groupid, ext, duration) => {
    const msg = new websdk.message('cmd', conn.getUniqueId());
    const {callId, callerDevId, calleeDevId} = ext;
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        action: 'rtcCall',
        ext: {
            action: 'confirmCallee1',
            callId,
            callerDevId,
            calleeDevId,
            duration,
            msgType: 'rtcCallWithAgora',
            ts: Date.now(),
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    sendVideoEventMsg(groupid, duration, '1');
    return conn.send(msg.body);
};

// 5. 视频通话结束 confirmCallee1(任意端发送)
export const sendConfirmCalleeMsg2 = (groupid, ext) => {
    const msg = new websdk.message('cmd', conn.getUniqueId());
    const {callId, callerDevId, calleeDevId, result} = ext;
    console.log(123, ext);
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        action: 'rtcCall',
        ext: {
            action: 'confirmCallee',
            callId,
            callerDevId,
            calleeDevId,
            result,
            msgType: 'rtcCallWithAgora',
            ts: Date.now(),
        }, // 消息扩展
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
            console.log('confirmcallee.....发送成功', s);
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });

    console.log(234, ext);
    // sendVideoEventMsg(groupid, duration, '1');
    return conn.send(msg.body);
};
// 1. 发送视频邀请(企业端发送)
export const sendVideoEventMsg = async (groupid, time, accepted) => {
    const msg = new websdk.message('custom', conn.getUniqueId());
    // 求职者Id
    msg.set({
        to: groupid, // 接收消息对象（用户id）
        customEvent: 'videoEvent', // 创建自定义事件
        customExts: {
            time,
            accepted,
            muteVideo: '2',
        },
        ext: {},
        roomType: false,
        chatType: 'groupchat',
        success: function (s) {
        },
        fail: function (e) {
            console.log({
                message: e.message || i18n.t('发送失败'),
                type: 'error',
            });
            console.log(e);
        },
    });
    return conn.send(msg.body);
};
