import {conn} from './init';
import dayjs from 'dayjs';
import store from '@/store';
import {fomatter} from './msg';
import i18n from '@/locale';
import {initEvent} from "@/assets/js/easemob/event";

// 获取用户最近的历史消息记录 100 条...
export const getHistoryMsg = (queue, msgs) => {
    console.log('getHistoryMsg', queue, '获取历史消息', '传输过来的消息', msgs);
    if (queue === store.state.admin.uid) {
        console.log('获取与管理员的消息')
        if (msgs && msgs.length > 0) {
            console.log('之前有消息，返回空')
            return [];
        } else {
            console.log('之前没哟消息')
            return conn.fetchHistoryMessages({queue, isGroup: false, count: 50});
            // return new Promise((resolve, reject) => {
            //     console.log('获取与管理员的历史消息1')
            //     if (msgs && msgs.length > 0) return [];
            //
            //     conn.fetchHistoryMessages({queue, isGroup: false, count: 50}).then(res => {
            //         console.log(res, 'rrr')
            //     })
            //     conn.getHistoryMessages({
            //         chatType: "singleChat",
            //         pageSize: 50,
            //         searchDirection: 'down',
            //         targetId: queue
            //     }).then(res => {
            //         console.log(res, 'eee')
            //          resolve(res.messages)
            //     }).catch(err=> {
            //         reject()
            //     })
            // })
        }
    } else {
        console.log('获取与其他用户的历史消息')
        if (msgs && msgs.length > 0) return [];
        return conn.fetchHistoryMessages({queue, isGroup: true, count: 50});
    }

};

// 订阅用户消息状态
export const subscribeUsers = async (_usernames = []) => {
    console.log('订阅用户消息状态', JSON.parse(JSON.stringify(_usernames)), '_usernames')
    if (!conn) return {};
    let result = [];
    let usernames = _usernames;
    while (true) {
        if (_usernames.length > 100) {
            usernames = usernames.slice(0, 100);
            _usernames = _usernames.slice(100);
        } else {
            usernames = _usernames;
            _usernames = [];
        }
        let {uid} = store.state.admin1
        for (let i = 0; i < usernames.length; i++) {
            // console.log(usernames[i], uid)
            if (usernames[i] == uid) {
                console.log('剔除自己')
                usernames.splice(i, 1)
                i--
            }
        }
        console.log('订阅用户消息状态12', usernames, '_usernames')
        if (usernames.length > 0) {
            const {result: _result} = await conn.subscribePresence({
                usernames, expiry: 7 * 24 * 60 * 60, error: err => {
                    console.log(err, '订阅失败')
                }, success: success => {
                    console.log(success, '订阅成功')
                }
            })
            result = [...result, ..._result];
            // console.log('result', _result)
            if (_usernames.length === 0) break;
        } else {
            console.log('usernames为空')
            break
        }
    }
    return {result};
};

// 检查用户是否在线
export const checkUserIsLogin = async ids => {
    const {result} = await subscribeUsers(ids);
    return result;
};

let me = '';

/**
 * 处理消息
 * @param _msg
 * @param msg
 * @param isNewMsg
 * @returns {*}
 */
export const handleMsg = (_msg, msg, isNewMsg) => {
    console.log('消息处理1')
    console.log('消息处理', _msg, msg, isNewMsg)
    if (_msg.length === 0 && msg.length === 0) {
        store.state.disableInput = false;
        store.state.reloadIdx++;
        return msg;
    }
    let currentTime;
    console.log('_msg', _msg, '历史消息列表');
    console.log('_msg', JSON.parse(JSON.stringify(_msg)), '历史消息列表历史消息列表');
    //没有消息的时候，添加日期到消息列表
    if (msg.length === 0) {
        currentTime = Number(_msg[0].time || Date.now());
        msg.push({
            contentsType: 'CENTER',
            _currentTime: currentTime,
            value: dayjs(currentTime).format('YYYY-MM-DD HH:mm:ss'),
        });
    }
    //有消息的时候，在字段内处理时间
    else {
        for (const {contentsType, _currentTime} of msg) {
            if (contentsType === 'CENTER' && _currentTime) {
                currentTime = Number(_currentTime);
            }
        }
    }

    for (let item of _msg) {
        // console.log('handleMsg......', item);
        if (!item) continue;
        const {time, contentsType, from, customEvent, customExts, ext} = item;
        let curTime = Number(time);

        // 消息排序
        if (curTime - currentTime >= 5 * 60 * 1000) {
            if (msg[msg.length - 1]._currentTime) {
                msg[msg.length - 1] = {
                    contentsType: 'CENTER',
                    _currentTime: curTime,
                    value: dayjs(curTime).format('YYYY-MM-DD HH:mm:ss'),
                };
            } else {
                msg.push({
                    contentsType: 'CENTER',
                    _currentTime: curTime,
                    value: dayjs(curTime).format('YYYY-MM-DD HH:mm:ss'),
                });
            }
            currentTime = curTime;
        }


        if (!me) me = store.state.me.user;
        let msgObj = {contentsType};
        msgObj.isLeft = from !== me;
        if (contentsType === 'CUSTOM') {
            if (!isNaN(Number(customEvent[0]))) {
                msgObj.contentsType = 'TEXT';
                let {nType, content, ext} = customExts;
                console.log(content, 'content content content content content content')
                try {
                    ext = JSON.parse(ext);
                } catch (e) {
                    console.log(e);
                }
                if (content.includes('${seeMore}')) content = content.replace('${seeMore}', `<a target='_blank' href='/company/company-show-${ext.companyId}-.htm'>${ext.seeMore}</a>`);
                if (content.includes('${serverName1}')) content = content.replaceAll('${serverName1}', `<a target='_blank' href='#'>${ext.serverName1}</a>`);
                if (content.includes('${serverName2}')) content = content.replaceAll('${serverName2}', `<a target='_blank' href='#'>${ext.serverName2}</a>`);
                if (content.includes('${companyName}')) {
                    if (nType === 1004) {
                        content = ext.companyId
                            ? content.replaceAll('${companyName}', `<a target='_blank' href='/company/company-show-${ext.companyId}-.htm#evaluation_list'>${ext.companyName}</a>`)
                            : content.replaceAll('${companyName}', `<a>${ext.companyName}</a>`);
                    } else {
                        content = ext.companyId
                            ? content.replaceAll('${companyName}', `<a target='_blank' href='/company/company-show-${ext.companyId}-.htm'>${ext.companyName}</a>`)
                            : content.replaceAll('${companyName}', `<a>${ext.companyName}</a>`);
                    }
                }
                if (content.includes('${jobName}')) {
                    content = ext.jobId
                        ? content.replaceAll('${jobName}', `<a target='_blank' href='/jobs/jobs-show-${ext.jobId}-default.htm'>${ext.jobName}</a>`)
                        : content.replaceAll('${jobName}', `<a>${ext.jobName}</a>`);
                }
                if (content.includes('${displayName')) {
                    content = ext.resumeId
                        ? content.replaceAll('${displayName}', `<a target='_blank' href='/resume/resume-show-${ext.resumeId}-.htm'>${ext.displayName}</a>`)
                        : content.replaceAll('${displayName}', `<a>${ext.displayName}</a>`);
                }

                if (content.includes('${time}')) {
                    content = content.replaceAll('${time}', `<a>${ext.time}</a>`);
                }
                if (content.includes('${receiptAmount}')) {
                    content = content.replaceAll('${receiptAmount}', `<a>${ext.receiptAmount}</a>`);
                }
                if (content.includes('${receiptType}')) {
                    content = content.replaceAll('${receiptType}', `<a>${ext.receiptType}</a>`);
                }
                if (content.includes('${email}')) {
                    content = content.replaceAll('${email}', `<a>${ext.email}</a>`);
                }
                if (content.includes('${serviceName}')) {
                    content = content.replaceAll('${serviceName}', `<a>${ext.serviceName}</a>`);
                }
                msg.push({...msgObj, data: content});
                continue;
            }

            if (customEvent === 'rejectTelegram') {
                if (msgObj.isLeft) {
                    msg.push({contentsType: 'CENTER', value: i18n.t('对方已拒绝与您互换Telegram')});
                } else {
                    msg.push({contentsType: 'CENTER', value: i18n.t('您已拒绝与对方互换Telegram')});
                }
                continue;
            }

            if (customEvent === 'sendTelegram') {
                if (!msgObj.isLeft) {
                    msg.push({contentsType: 'CENTER', value: i18n.t('已发送Telegram号')});
                    continue;
                }
            }

            // if (customEvent === 'inviteInterview') {
            //   if (!msgObj.isLeft) {
            //     msg.push({ contentsType: 'CENTER', value: i18n.t('已发送面试邀请') });
            //     continue;
            //   }
            // }

            if (customEvent === 'acceptedEvent') {
                if (customExts.acceptEvent === 'inviteInterview') {
                    if (customExts.accepted === '1') {
                        if (msgObj.isLeft) {
                            msg.push({contentsType: 'CENTER', value: i18n.t('对方已同意您的面试邀请')});
                        } else {
                            const time = new Date(Number(customExts.interviewTime) * 1000);
                            let duration = time - Date.now();
                            const d = Math.floor(duration / (1000 * 60 * 60 * 24));
                            const h = Math.floor(duration / (1000 * 60 * 60)) % 24;
                            msg.push({contentsType: 'CENTER', value: i18n.t('您已同意对方的面试邀请', {d, h})});
                        }
                    } else {
                        msg.push({
                            contentsType: 'CENTER',
                            value: msgObj.isLeft ? i18n.t('对方拒绝了您的面试邀请') : i18n.t('你已拒绝视频面试邀请')
                        });
                    }
                    continue;
                }

                if (customExts.acceptEvent === 'modifyInterviewTime') {
                    if (customExts.accepted === '1') {
                        msg.push({
                            contentsType: 'CENTER',
                            value: msgObj.isLeft ? i18n.t('对方已同意修改面试时间') : i18n.t('您已接受对方修改面试时间')
                        });
                    } else {
                        msg.push({
                            contentsType: 'CENTER',
                            value: msgObj.isLeft ? i18n.t('对方拒绝了修改面试时间') : i18n.t('你拒绝了对方修改面试时间')
                        });
                    }
                    continue;
                }

                if (customExts.acceptEvent === 'sendTelegram' || customExts.acceptEvent === 'swapTelegram') {
                    msg.push({
                        contentsType: 'CENTER',
                        value: msgObj.isLeft ? i18n.t('对方已拒绝与您互换Telegram') : i18n.t('您已拒绝与对方互换Telegram'),
                    });
                    continue;
                }

                if (customExts.acceptEvent === 'applyInterview') {
                    if (customExts.accepted === '1') {
                        msg.push({
                            contentsType: 'CENTER',
                            value: msgObj.isLeft ? i18n.t('对方已同意您的面试申请') : i18n.t('您已同意对方面试申请')
                        });
                    } else {
                        msg.push({
                            contentsType: 'CENTER',
                            value: msgObj.isLeft ? i18n.t('对方已拒绝您的面试申请') : i18n.t('您已拒绝对方面试申请')
                        });
                    }
                    continue;
                }
            }
            if (customEvent === 'inviteVideo' || customEvent === 'videoEvent') {
                // if (!msgObj.isLeft) {
                //   msg.push({ contentsType: 'CENTER', value: i18n.t('您发起了视频邀请') });
                // } else {
                //   msg.push({ contentsType: 'CENTER', value: i18n.t('对方发起了视频邀请') });
                // }
                continue;
            }
        }
        if (contentsType === 'COMMAND') {
            const {action, result, duration} = ext;
            if (action === 'noAnswer') {
                if (!msgObj.isLeft) {
                    msg.push({
                        contentsType: 'CALL',
                        isLeft: false,
                        data: `<img src='${require('@/assets/img/hang up_icon@2x.png')}' /> ${i18n.t('对方无应答')}`,
                    });
                }
            }
            if (action === 'answerCall' && result === 'refuse') {
                msg.push({
                    contentsType: 'CALL',
                    isLeft: msgObj.isLeft,
                    data: `<img src='${require('@/assets/img/hang up_icon@2x.png')}' /> ${msgObj.isLeft ? i18n.t('对方已拒绝') : i18n.t('已拒绝')}`,
                });
            }

            if (action === 'cancelRing') {
                msg.push({
                    contentsType: 'CALL',
                    isLeft: msgObj.isLeft,
                    data: `<img src='${require('@/assets/img/hang up_icon@2x.png')}' /> ${msgObj.isLeft ? i18n.t('对方已取消') : i18n.t('已取消')}`,
                });
            }
            if (action === 'timeout') {
                msg.push({
                    contentsType: 'CALL',
                    isLeft: msgObj.isLeft,
                    data: `<img src='${require('@/assets/img/hang up_icon@2x.png')}' /> ${i18n.t('呼叫超时')}`,
                });
            }
            if (action === 'confirmCallee1') {
                let h = Math.floor(duration / 3600);
                if (h < 10) h = '0' + h;
                let m = Math.floor((duration % 3600) / 60);
                if (m < 10) m = '0' + m;
                let s = Math.floor(duration % 60);
                if (s < 10) s = '0' + s;
                msg.push({
                    contentsType: 'CALL',
                    isLeft: false,
                    data: `<img src='${require('@/assets/img/hang up_icon@2x.png')}' /> ${i18n.t('通话时长', {duration: `${h}:${m}:${s}`})}`,
                });
            }
            continue;
        }
        fomatter[contentsType](msgObj, item, isNewMsg);
        msg.push(msgObj);
    }
    let disabledInput = true;
    if (msg.length >= 1) {
        for (let i = 0; i < msg.length; i++) {
            const {isLeft, value, customEvent} = msg[i];
            if (isLeft || customEvent === 'getInTouch') disabledInput = false;
            if (value && typeof value.startsWith === 'function' && value.startsWith('提示: 只有当对方回复后, 才能继续聊天')) {
                msg.splice(i, 1);
            }
        }
    } else {
        disabledInput = false;
    }

    if (disabledInput) {
        const {isBoss} = store.state;
        const {applied, got} = store.state.downloadPage || {};
        if (applied === false && got === false) {
            store.state.disableInput = true;
            const {value} = msg[msg.length - 1];
            if (!value || typeof value.startsWith !== 'function' || !value.startsWith(i18n.t('提示: 只有当对方回复后, 才能继续聊天'))) {
                msg.push(
                    isBoss
                        ? {
                            contentsType: 'CENTER', // value: '提示: 只有当对方回复后, 才能继续聊天或者您也可以: <span id="contact" style="color:#25ca9c;cursor: pointer;">获取对方的联系方式</span>',
                            value: i18n.t('提示: 只有当对方回复后, 才能继续聊天') + i18n.t('或者您也可以:') + `<span id="contact" style="color:#25ca9c;cursor: pointer;">${i18n.t('获取对方的联系方式')}</span>`,
                        }
                        : {
                            contentsType: 'CENTER',
                            value: i18n.t('提示: 只有当对方回复后, 才能继续聊天'),
                        }
                );
            }
        } else {
            store.state.disableInput = false;
        }
    } else {
        store.state.disableInput = false;
    }
    setTimeout(() => {
        store.commit('updateState', {reloadIdx: store.state.reloadIdx + 1})
    });
};

let isToggling = false;
let titleToggleTimer = null;

function toggleTitle() {
    if (isToggling) return;
    isToggling = true;
    handleTitle();
}

function handleTitle() {
    let {title} = document;
    if (!title) title = ` [${i18n.t('新消息提醒')}] - CamboJob | Talk to CamboJob, Free Online Chat Rooms`;
    document.title = title.slice(1);
    if (isToggling) {
        titleToggleTimer = setTimeout(() => {
            handleTitle();
        }, 1000);
    }
}

/**
 * 有未阅读的消息
 * @param _num
 */
export const unreadMsgChange = (_num = 1) => {
    let num = localStorage.getItem('UNREAD_MSG');
    if (!num) num = 0;
    else num = parseInt(num);
    num += _num;
    num = num > 0 ? num : 0;
    if (num > 0) toggleTitle();
    else {
        clearTimeout(titleToggleTimer);
        isToggling = false;
        document.title = `CamboJob`;
    }
    localStorage.setItem('UNREAD_MSG', num);
};

export const randomLowerLetter = len => {
    const str = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < len; i++) {
        result += str[Math.floor(Math.random() * str.length)];
    }
    return result;
};
