/**
 * 管理员信息
 */
import {conn} from "@/assets/js/easemob/init";
import store from "@/store";
import {initConversationList} from "@/assets/js/easemob/conversation";
import {postMemberAvatars} from "@/services";

/**
 * 获取会话列表
 */
export function getConversationlist_admin() {
    console.log('获取管理员会话列表')
    return new Promise((resolve, reject) => {
        conn.getConversationlist().then(res => {
            console.log(res, '管理员获取会话列表成功')
            let list = res.data.channel_infos
            let arr = []
            list.map(item => {
                if (item.lastMessage.to == store.state.admin1.uid) {
                    arr.push(item)
                }
            })
            store.commit('updateShowContactList_admin', list)
            console.log(store.state.showContactList_admin, '管理员的会话列表')
            console.log('在全局内设置管理员会话列表')
            resolve(list)
        }).catch(err => {
            console.log(err, '管理员获取会话列表失败')
            // store.commit('updateShowContactList_admin', [])
            reject()
        })
    })
}

/**
 * 查询用户属性
 */
export async function fetchUserInfoById_admin() {
    let list = store.state.showContactList_admin
    console.log(list, 'list')
    let arr = []
    list.map(item => {
        arr.push(item.lastMessage.from)
    })
    console.log(arr, '收到的消息的人员id列表')
    conn.fetchUserInfoById(arr).then(res => {
        console.log('用户属性获取成功', res)
    }).catch(err => {
        console.log('用户属性获取失败', err)
    })
}

/**
 * 查找管理员聊天记录 并更新
 * 如果列表内没有该管理员，则在会话列表内添加该联系人
 */
export function getHistoryMessages_admin(item) {
    console.log(item, '查找管理员聊天记录 并更新')
    conn.getHistoryMessages({
        chatType: "singleChat",
        // cursor: '-1',
        pageSize: 50,
        searchDirection: 'up',
        targetId: item.lastMessage.from == store.state.admin1.uid ? item.lastMessage.to : item.lastMessage.from,
    }).then(res => {
        console.log(res, '获取消息成功')
        console.log(res, '获取消息成功管理员12')
        let list = res.messages.reverse()
        store.commit('updateMessageList_admin', list)
        setTimeout(() => {
            scrollToLastMessage()
        }, 100)
        let isAddMessage = window.sessionStorage.getItem('isAddMessage')
        //如果不是新添加消息，则重新加载列表
        if (!isAddMessage) {
            console.log('如果新添加消息，则重新加载列表')
            initConversationList()
        } else {
            console.log('只需要更新消息')
        }
        //    发送已读回执
        let option = {
            chatType: "singleChat", // 会话类型，设置为单聊。
            type: "channel", // 消息类型。
            to: item.lastMessage.from == store.state.admin1.uid ? item.lastMessage.to : item.lastMessage.from, // 接收消息对象的用户 ID。
        };
        let msg = WebIM.message.create(option);
        conn.send(msg);
    }).catch(err => {
        console.log(err, '获取消息失败')
    })
}

/**
 * 消息自动滚到最新
 */
export function scrollToLastMessage() {
    // let list = store.state.messageList
    // let len = list.length
    // let id = list[len - 1].id
    // console.log(`id${id}`, '需要滚动到的消息ID')
    // window.location.hash = `id${id}`
    let ele = document.getElementById('middle-info')
    console.log(ele)
    ele.scrollTop = ele.scrollHeight
}

/**
 * 其他账号收到管理员消息
 */
export function otherUpdateMessage(item) {

    store.dispatch('changeConversation', item.groupid || item).then(res => {
        console.log(res, 'rrrrrrrrrrrrrrr')
    }).catch(err => {
        console.log(err, 'eeeeeeeeeeeeee')
    })
}

