import { getAgoraToken } from '@/services';
import AgoraRTC from './AgoraRTC_N-4.11.1';
import store from '@/store';
import Vue from 'vue';
import { sendCancelVideoMsg, sendConfirmCalleeMsg } from '../easemob/msg';

const isSupported = AgoraRTC.checkSystemRequirements();

let client;
if (!isSupported) {
  alert('Your browser does not support WebRTC!');
}

export const joinChannel = async (channel, isOpen) => {
  // 通话模式，VP8 视频编码格式
  client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
  client.enableAudioVolumeIndicator();
  client.setClientRole('host');
  client.on('user-published', handleUserPublished);
  client.on('user-info-updated', handleUserInfoUpdated);
  client.on('volume-indicator', handleVolumeIndicator);
  client.on('user-left', handleUserLeave);
  const { accessToken, agoraUserId } = await getAgoraToken(channel);
  const { options, localTracks } = store.state;
  [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
    client.join(options.appid, channel, accessToken, agoraUserId),
    AgoraRTC.createMicrophoneAudioTrack(),
    AgoraRTC.createCameraVideoTrack(),
  ]);
  await client.publish(Object.values(localTracks));
  localTracks.videoTrack.play('local-video');
  store.state.agora = { channel, accessToken, agoraUserId };
  if (!isOpen) {
    toggleVideo(true);
  }
  return client;
};

const handleVolumeIndicator = async result => {
  result.forEach(volume => {
    const { uid, level } = volume;
    if (store.state.remoteUser.uid === Number(uid)) {
      store.state.remoteUserVolume = Number(level);
    }
  });
};

const handleUserInfoUpdated = async (user, mediaType) => {
  console.log('user-info-updated', user, mediaType);
  if (mediaType === 'mute-video') {
    store.state.trackState.remoteVideoTrackMuted = true;
  }
  if (mediaType === 'unmute-video') {
    store.state.trackState.remoteVideoTrackMuted = false;
  }
};

const handleUserPublished = async (user, mediaType) => {
  clearTimeout(store.state.callTimer);
  store.state.videoStatus = '2';
  if (!store.state.isContact) {
    store.state.isContact = true;
  }
  setInterval(() => {
    const { Duration } = client.getRTCStats();
    store.state.duration = Duration;
  }, 1000);
  const loading = window.$vm.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
  Vue.nextTick(async () => {
    await client.subscribe(user, mediaType);
    store.state.remoteUser = user;

    if (mediaType === 'video') user.videoTrack.play('remote-video');
    if (mediaType === 'audio') user.audioTrack.play();
    const { isBoss, localTracks, trackState, remoteUser } = store.state;
    if (isBoss) {
      if (trackState.videoTrackMuted) localTracks.videoTrack.setEnabled(false);
      if (trackState.audioTrackMuted) localTracks.audioTrack.setEnabled(false);
    } else if (!isBoss) {
      const { v } = window.$vm.$route.query;
      if (v !== '1') {
        setTimeout(() => {
          trackState.videoTrackMuted = true;
          localTracks.videoTrack.setEnabled(false);
        }, 1000);
      }
    }

    const isMuted = trackState.remoteAudioTrackMuted;
    if (!remoteUser.audioTrack) return;
    if (isMuted) {
      if (remoteUser.audioTrack.setVolume) remoteUser.audioTrack.setVolume(0);
    } else {
      if (remoteUser.audioTrack.setVolume) remoteUser.audioTrack.setVolume(100);
    }
  });
  loading.close();
};

export const handleUserLeave = async () => {
  console.log('user-leave');
  const loading = window.$vm.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
  try {
    const { groupid, ext } = JSON.parse(localStorage.getItem('IM_VIDEO_DATA'));
    sendConfirmCalleeMsg(groupid, ext, String(store.state.duration));
    await client.leave();
    if (store.state.isBoss) {
      store.state.videoStatus = '1';
    } else {
      store.state.videoStatus = '4';
    }
  } finally {
    loading.close();
  }
};

export const toggleAudio = async status => {
  const { trackState, localTracks } = store.state;
  const isMuted = trackState.audioTrackMuted;
  trackState.audioTrackMuted = typeof status !== 'undefined' ? status : !isMuted;
  if (localTracks.audioTrack.setEnabled) localTracks.audioTrack.setEnabled(isMuted);
};

export const toggleVideo = status => {
  const { trackState, localTracks } = store.state;
  const isMuted = trackState.videoTrackMuted;
  trackState.videoTrackMuted = typeof status !== 'undefined' ? status : !isMuted;
  if (localTracks.audioTrack.setEnabled) localTracks.videoTrack.setEnabled(isMuted);
};

export const toggleRemoteAudio = async status => {
  const { remoteUser, trackState } = store.state;
  const isMuted = trackState.remoteAudioTrackMuted;
  trackState.remoteAudioTrackMuted = typeof status !== 'undefined' ? status : !isMuted;
  if (!remoteUser.audioTrack) return;
  if (isMuted) {
    if (remoteUser.audioTrack.setVolume) remoteUser.audioTrack.setVolume(0);
  } else {
    if (remoteUser.audioTrack.setVolume) remoteUser.audioTrack.setVolume(100);
  }
};

export const leaveRoom = async () => {
  const loading = window.$vm.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
  try {
    await client.leave();
    if (store.state.isBoss) {
      store.state.videoStatus = '1';
    } else {
      store.state.videoStatus = '4';
    }
  } finally {
    loading.close();
  }
};
