import websdk from 'easemob-websdk';
import { config as webimConfig } from './config';
import { initEvent } from './event';
import store from '@/store';
import { getMemberPwd } from '@/services';
import { getGroupIDByConfig } from './conversation';
import { sendAnswerCallMsg, sendInviteVideoMsgByConfig, sendTextMsg } from './msg';

const WebIM = (window.WebIM = websdk);
export const conn = (WebIM.conn = new WebIM.connection({
  appKey: webimConfig.appkey,
  host: webimConfig.Host,
  isHttpDNS: webimConfig.isHttpDNS,
  isMultiLoginSessions: webimConfig.isMultiLoginSessions,
  https: webimConfig.https,
  url: webimConfig.xmppURL,
  apiUrl: webimConfig.apiURL,
  isAutoLogin: true,
  heartBeatWait: webimConfig.heartBeatWait,
  // autoReconnectNumMax: webimConfig.autoReconnectNumMax,
  // autoReconnectInterval: webimConfig.autoReconnectInterval,
  isStropheLog: webimConfig.isStropheLog,
  delivery: webimConfig.delivery,
}));

window.conn = conn;

export const initIM = async (autoSelect, fn) => {
  const { uid, utype, pwd, nickname, info_completed, telegram } = await getMemberPwd();
  if (!uid) return;
  const user = [uid, utype].join('-');
  initEvent(conn, true, autoSelect, {}, fn);
  store.commit('updateState', {
    isBoss: utype === '1',
    me: { uid, utype, pwd, user, appKey: webimConfig.appkey, nickname, info_completed, telegram },
    uid: user,
  });
  await conn.open({ user, pwd, appKey: webimConfig.appkey });
};

export const initIMConfig = async config => {
  const { uid, utype, pwd, nickname } = await getMemberPwd();
  if (!uid) return;
  const user = [uid, utype].join('-');
  console.log('IM 获取到 config', config);
  store.commit('updateState', {
    isBoss: utype === '1',
    me: { uid, utype, pwd, user, appKey: webimConfig.appkey, nickname },
    uid: user,
  });
  initEvent(conn, false, false, config);
  console.log('IM 连接状态:::', conn.context.isConnected);
  if (!conn.context.isConnected) {
    await conn.open({ user, pwd, appKey: webimConfig.appkey });
  } else {
    if (!config) return;
    if (config.text) {
      const groupid = await getGroupIDByConfig(config);
      await sendTextMsg(groupid, config.text);
      localStorage.setItem('IM_SEND_HELLO_SUCCESS', 1);
    } else if (config.reject) {
      const { groupid } = JSON.parse(localStorage.getItem('IM_VIDEO_DATA'));
      sendAnswerCallMsg(groupid, {}, 'refuse');
    } else if (config.call) {
      const groupid = await getGroupIDByConfig(config);
      await sendInviteVideoMsgByConfig(groupid, config);
      window.open('/chatapp/video?v=1', 'interview_page');
    }
  }
};
