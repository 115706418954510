export default {
    lang: 'zh',

    面试邀请: '面试邀请',
    查看简历: '查看简历',
    发送Telegram号: '发送Telegram号',
    请输入您的Telegram号: '请输入您的Telegram号',
    请选择面试方式: '请选择面试方式',
    线下面试: '线下面试',
    视频面试: '视频面试',
    请输入面试地点: '请输入面试地点',
    请输入备注说明: '请输入备注说明',
    请选择简历: '请选择简历',
    取消: '取消',
    投递简历成功: '投递简历成功',
    接受: '接受',
    备注说明: '备注说明',
    确定: '确定',
    拒绝: '拒绝',
    同意: '同意',
    面试时间修改: '面试时间修改',
    的Telegram账号: '的Telegram账号',
    复制账号: '复制账号',
    复制成功: '复制成功',
    的简历: '的简历',
    发送: '发送',
    // 提醒
    未连接请稍后再试: '未连接,请稍后再试',
    发送失败: '发送失败',
    格式不支持: '格式不支持',
    您向对方发送了互换Telegram请求: '您向对方发送了互换Telegram请求',
    已发送Telegram号: '已发送Telegram号',
    您向对方发送了面试申请: '您向对方发送了面试申请',
    您向对方发送了面试邀请: '您向对方发送了面试邀请',
    您已同意面试邀请: '您已同意面试邀请',
    你已拒绝视频面试邀请: '你已拒绝视频面试邀请',
    您已接受对方修改面试时间: '您已接受对方修改面试时间',
    你已拒绝对方修改面试时间: '你已拒绝对方修改面试时间',
    '[简历]': '[简历]',
    '[互换Telegram]': '[互换Telegram]',
    '[申请面试]': '[申请面试]',
    '[邀请面试]': '[邀请面试]',
    '[修改面试时间]': '[修改面试时间]',
    对方已同意您的面试邀请: '对方已同意您的面试邀请',
    对方拒绝了您的面试邀请: '对方拒绝了您的面试邀请',
    对方已同意修改面试时间: '对方已同意修改面试时间',
    对方拒绝了修改面试时间: '对方拒绝了修改面试时间',
    // 视频部分
    岁: '岁',
    您发起了视频邀请: '您发起了视频邀请',
    对方已拒绝: '对方已拒绝',
    'id 不能为空': 'id 不能为空',
    '你可以发一份简历给我吗?': '你可以发一份简历给我吗?',
    你需要先获取对方的资料: '你需要先获取对方的资料',
    马上获取: '马上获取',
    '申请面试将默认发送您的简历给对方!': '申请面试将默认发送您的简历给对方!',
    确认: '确认',
    您已获取对方的联系方式: '您已获取对方的联系方式',
    对方已获取您的联系方式: '对方已获取您的联系方式',
    请先完成引导: '请先完成引导',
    正在咨询: '正在咨询',

    对方已同意您的面试申请: '对方已<span class="green">同意</span>您的面试申请',
    对方已拒绝您的面试申请: '对方已<span class="red">拒绝</span>您的面试申请',

    '[视频面试邀请]': '[视频面试邀请]',
    '[联系方式]': '[联系方式]',
    '[语音消息]': '[语音消息]',

    您已同意对方面试申请: '您已同意对方面试申请',
    您已拒绝对方面试申请: '您已拒绝对方面试申请',
    登录: '登录',

    对方向您发送了互换Telegram请求: '对方向您发送了互换Telegram请求',
    您已拒绝与对方互换Telegram: '您已拒绝与对方互换Telegram',
    对方已拒绝与您互换Telegram: '对方已拒绝与您互换Telegram',
    已拒绝: '已拒绝',
    已同意: '已同意',

    管理员: '管理员',
    对方无应答: '对方无应答',
    对方已取消: '对方已取消',
    已取消: '已取消',
    呼叫超时: '呼叫超时',
    通话时长: '通话时长 {duration}',
    '提示: 只有当对方回复后, 才能继续聊天': '提示: 只有当对方回复后, 才能继续聊天',
    '或者您也可以:': '或者您也可以:',
    获取对方的联系方式: '获取对方的联系方式',
    id不能为空: 'id不能为空',

    搜索会话: '搜索会话',
    删除会话: '删除会话',
    在这里输入: '在这里输入',
    查看详情: '查看详情',

    姓名: '姓名',
    微信: '微信',
    手机: '手机',
    邮箱: '邮箱',

    我的Telegram为: '我的Telegram为',
    // 面试

    // 预约面试
    面试主题: '面试主题',
    请输入面试主题: '请输入面试主题',
    面试时间: '面试时间',
    面试方式: '面试方式',
    面试地点: '面试地点',
    选择日期时间: '选择日期时间',
    // 沟通职位
    请选择要沟通的职位: '请选择要沟通的职位',

    // 选择简历
    选择简历: '选择简历',
    更新: '更新',
    完成度: '完成度',

    // 视频通话
    视频邀请: '视频邀请',
    工作面试: '工作面试',
    我: '我',
    对方未开启摄像头: '对方未开启摄像头',
    视频通话结束: '视频面试结束',
    企业中心: '企业中心',

    // 面试评价
    面试评价: '面试评价',
    你觉得面试怎么样: '你觉得面试怎么样',
    评价成功: '评价成功',
    '我们已经收到您的反馈, 感谢您的耐心填写, 期待您找到好工作': '我们已经收到您的反馈, 感谢您的耐心填写, 期待您找到好工作',
    返回首页: '返回首页',
    返回个人中心: '返回个人中心',
    保存: '保存',
    关闭: '关闭',

    预览: '预览',
    邀请您面试: '{contact}邀请您面试',
    您邀请面试: '您邀请 {name} {type} 面试',
    视频: '视频',
    线下: '线下',
    '您好, 请问可以在线视频面试吗 ?': '您好, 请问可以在线视频面试吗 ?',
    默认: '默认',
    简介: '简介',
    历史记录: '历史记录',
    薪资: '薪资',
    工作经验: '工作经验',
    学历: '学历',
    语言: '语言',
    工作内容: '工作内容',
    暂无工作经验: '暂无工作经验',
    自我评价: '自我评价',
    在线: '在线',
    月: '月',
    咨询: '咨询',

    // 补充
    暂无简历: '暂无简历',
    通话已挂断: '通话已挂断',
    您已同意对方的面试邀请: '您已<b class="green">同意</b>对方的面试邀请, 距离约定的面试时间剩余 {d} 天 {h} 小时',
    您已拒绝对方的面试邀请: '您已<b class="red">拒绝</b>对方的面试邀请',
    新消息提醒: '新消息提醒',

    我想要申请一次面试机会: '我想要申请一次面试机会',
    请先取消隐藏在线简历: '请先取消隐藏在线简历',
    对方操作后会有提示回执: '对方操作后会有提示回执',
    收到线上面试: '<span class="green">{name}</span>公司，邀请您在 <span class="green">{y}.{M}.{d} {h}:{m}</span>，通过视频进行线上面试，您是否同意？',
    收到线下面试: '<span class="green">{name}</span>公司，邀请您在 <span class="green">{y}.{M}.{d} {h}:{m}</span>，到<span class="green">{address}</span>面试，您是否同意？',

    发起线上面试: '您已邀请 <span class="green">{name}</span> 在  <span class="green">{y}.{M}.{d} {h}:{m}</span>，进行线上视频面试。',
    发起线下面试: '您已邀请<span class="green">{name}</span>在  <span class="green">{y}.{M}.{d} {h}:{m}</span>，到<span class="green">{address}</span>面试。',
    您还没有编辑在线简历: '您还没有编辑在线简历',
    知道了: '知道了',
    去编辑: '去编辑',
    联系人: '联系人',
    联系电话: '联系电话',

    请选择身份: '请选择身份',
    我是企业: '我是企业',
    我是求职者: '我是求职者',
};
