import store from '@/store';
import {Message} from 'element-ui';
import {conn} from './init';
import {postGetJob, postMemberAvatars} from '@/services';
import {sendJobCardMsg} from '@/assets/js/easemob/msg';
import {fetchUserInfoById_admin, getConversationlist_admin} from "@/assets/js/easemob/admin";

/**
 * 开始对话
 * @param option
 * @returns {Promise<*>}
 */
export const beginConversation = async option => {
    const {uid, jobName, jobsId, resume_id} = option;
    const {isLogin, isBoss, me, originUserList} = store.state;
    const groupInfo = {
        jobName,
        jobsId: Number(jobsId),
        resume_id: Number(resume_id) || undefined,
        unfreeze: false,
        boss: isBoss ? me.uid : uid,
        uid: uid,
        talent: isBoss ? uid : me.uid,
    };
    if (!isLogin) return Message({
        message: i18n.t('未连接请稍后再试'), type: 'warning',
    });

    let b = groupInfo;
    const idx = originUserList.findIndex(({group: a = {}}) => [a.boss, a.talent, a.jobsId].join('') === [b.boss, b.talent, b.jobsId].join(''));
    if (idx !== -1) return store.dispatch('changeConversation', originUserList[idx].groupid);
    return createConversation(groupInfo, resume_id);
};

/**
 * 按设置获取组ID
 * @param config
 * @returns {Promise<string>}
 */
export const getGroupIDByConfig = async config => {
    const {jobName, jobsId, resume_id, boss, talent, isBoss} = config;
    const groupInfo = {
        jobName, jobsId: Number(jobsId), resume_id: Number(resume_id) || undefined, unfreeze: false, boss, talent,
    };
    const b = groupInfo;
    const {data} = await conn.getJoinedGroups({pageNum: 1, pageSize: 1000});
    const res = data.find(v => {
        const a = JSON.parse(v.groupname);
        return [a.boss, a.talent, a.jobsId].join('') === [b.boss, b.talent, b.jobsId].join('');
    });
    if (res && res.groupid) return res.groupid;
    // await createConversation(groupInfo);
    const str = JSON.stringify(groupInfo);
    const {data: group} = await conn.createGroup({
        data: {
            groupname: str,
            desc: str,
            members: [boss + '-1', talent + '-2'],
            public: false,
            approval: false,
            allowinvites: true,
            inviteNeedConfirm: false,
        },
    });

    // 添加管理员，设置群管理员
    await conn.setGroupAdmin({
        groupId: group.groupid, username: isBoss ? talent + '-2' : boss + '-1',
    });

    const {maxwage, minwage} = await postGetJob({job_id: jobsId});
    await sendJobCardMsg(group.groupid, {id: jobsId + "", maxwage, minwage, jobsName: jobName});
    return group.groupid;
};

/**
 * 创建对话
 * @param option
 * @param resume_id
 * @returns {Promise<any>}
 */
export const createConversation = async (option, resume_id) => {
    const {state} = store;
    const {isBoss, originUserList, groupUserMap, userInfoMap} = state;
    const str = JSON.stringify(option);
    const bossID = option.boss + '-1';
    const talentID = option.talent + '-2';
    const {data: group} = await conn.createGroup({
        data: {
            groupname: str,
            desc: str,
            members: [bossID, talentID],
            public: false,
            approval: false,
            allowinvites: true,
            inviteNeedConfirm: false,
        },
    });
    const _userInfoMap = await postMemberAvatars([bossID, talentID]);
    console.log(_userInfoMap, '_userInfoMap_userInfoMap_userInfoMap');
    Object.keys(_userInfoMap).forEach(v => {
        userInfoMap[v] = _userInfoMap[v];
    });

    // 添加管理员
    await conn.setGroupAdmin({
        groupId: group.groupid, username: isBoss ? talentID : bossID,
    });

    // 初始化数据
    const admin = originUserList.shift();
    originUserList.unshift({
        groupid: group.groupid, group: option, groupname: str, to: isBoss ? talentID : bossID, unread_num: 0,
    });
    originUserList.unshift(admin);
    groupUserMap[group.groupid] = option;
    state.groupMsg[group.groupid] = [];

    const {maxwage, minwage} = await postGetJob({job_id: option.jobsId});
    await sendJobCardMsg(group.groupid, {id: option.jobsId + "", maxwage, minwage, jobsName: option.jobName});
    initConversationList();
    return store.dispatch('changeConversation', group.groupid);
};

/**
 * 初始化会话列表
 * @returns {[*,...*[],...*[]]}
 */
export async function initConversationList() {
    const {originUserList, userStatus} = store.state;
    console.log(originUserList, '列表内所有人')
    console.log(userStatus, '列表内人员状态');
    let adminUser = originUserList.shift();
    console.log(adminUser, 'adminUser')
    const onlineUser = originUserList.filter(v => userStatus[v.to]);
    const offlineUser = originUserList.filter(v => !userStatus[v.to]);
    console.log(JSON.parse(JSON.stringify(onlineUser)));
    onlineUser.sort((a, b) => {
        const {timestamp: at} = a.meta || {};
        const {timestamp: bt} = b.meta || {};
        return at - bt > 0 ? -1 : 1;
    });
    offlineUser.sort((a, b) => {
        const {timestamp: at} = a.meta || {};
        const {timestamp: bt} = b.meta || {};
        return at - bt > 0 ? -1 : 1;
    });

    console.log(store.state.me, '这是我的环信信息')
    console.log(adminUser, '用户列表第一个')


    console.log(store.state.admin1, 'store.state.admin1')

    //如果是管理员
    if (store.state.me.user == "2176-1" || store.state.me.user == "363440-1" || store.state.me.user == "438121-1" || store.state.me.user == "399682-1") {
        let obj = {
            uid: store.state.me.user,
            unread_num: 0
        }
        store.commit('updateAdmin1', obj)
        store.commit('updateAdmin', obj)
        console.log(store.state.admin1, 'store.state.admin1--')
        console.log('如果是管理员，就不用在列表内添加管理员')
        await getConversationlist_admin().then((res) => {
            console.log(res)
            fetchUserInfoById_admin().then(res1 => {
                console.log(store.state.showContactList_admin, '返回的管理员会话列表')
                let arr = []
                let list = store.state.showContactList_admin
                let id_ = ''
                list.map(item => {
                    if (item.lastMessage.from == '2176-1' || item.lastMessage.from == '363440-1' || item.lastMessage.from == '438121-1' || item.lastMessage.from == '399682-1') {
                        id_ = item.lastMessage.to
                    } else {
                        id_ = item.lastMessage.from
                    }
                    arr.push(id_)
                })
                console.log(arr, '返回的管理员会话列表_头像和昵称');
                let arr1 = JSON.parse(JSON.stringify(store.state.showContactList_admin))
                let arr2 = []
                if (arr.length > 0) {
                    postMemberAvatars(arr).then(res123 => {
                        console.log(res123, 'res123');
                        arr2 = res123
                        for (let key in arr2) {
                            if (key == store.state.admin1.uid) {
                                delete arr2[key]
                            }
                        }
                        let keys = Object.keys(arr2)
                        console.log(keys, 'keys');
                        arr1.map(item => {
                            keys.map(key => {
                                if (item.lastMessage.from == key || item.lastMessage.to == key) {
                                    item.nickname = arr2[key].nickname
                                    item.avatar = arr2[key].avatar
                                    item.uid = key
                                }
                            })
                        })
                        console.log(arr1, '带头像和昵称以及未读消息数量的的管理员会话列表信息');
                        //如果已经在当前会话中，则未读消息数量为0
                        arr1.map(item => {
                            if (item.uid == store.state.target_admin.uid) {
                                item.unread_num = 0
                            }
                        })
                        store.commit('updateShowContactList_admin', arr1)
                        store.commit('updateShowContactList_admin_avatar', arr1)
                        store.commit('updateShowContactList_admin_avatar_', arr1)

                    }).catch(err123 => {
                        console.log(err123, 'err123');
                    })
                } else {
                    console.log('返回的管理员会话列表为空');
                }
            }).catch(err1 => {
                console.log(err1)
            })
        }).catch(err => {
            console.log(err)
        })
        // await fetchUserInfoById_admin()

    }
    //如果不是管理员
    else {
        console.log('如果不是管理员，则在列表内添加管理员账号111112')
        let val = {
            uid: '',
            //unread_num: 0
        }
        if (window.location.href.indexOf('test.cambojob.com') > -1) {
            console.log('这是测试环境');
            if (store.state.me.utype == 2) {
                // adminUser = {
                //     groupid: '363440-1',
                //     to: '363440-1',
                //     //unread_num: 0
                // }
                adminUser.groupid = '363440-1'
                adminUser.to = '363440-1'
                val.uid = '363440-1'
                store.commit('updateAdmin1', val)
                store.commit('updateAdmin', val)
            } else {
                // adminUser = {
                //     groupid: '2176-1',
                //     to: '2176-1',
                //     //unread_num: 0
                // }
                adminUser.groupid = '2176-1'
                adminUser.to = '2176-1'
                val.uid = '2176-1'
                store.commit('updateAdmin1', val)
                store.commit('updateAdmin', val)
            }
        } else {
            console.log('这不是测试环境');
            if (store.state.me.utype == 2) {
                // adminUser = {
                //     groupid: '399682-1',
                //     to: '399682-1',
                //     //unread_num: 0
                // }
                adminUser.groupid = '399682-1'
                adminUser.to = '399682-1'
                val.uid = '399682-1'
                store.commit('updateAdmin1', val)
                store.commit('updateAdmin', val)
            } else {
                // adminUser = {
                //     groupid: '438121-1',
                //     to: '438121-1',
                //     //unread_num: 0
                // }
                adminUser.groupid = '438121-1'
                adminUser.to = '438121-1'
                val.uid = '438121-1'
                store.commit('updateAdmin1', val)
                store.commit('updateAdmin', val)
            }
        }
        console.log(adminUser, '用户列表第一个1112')
        console.log(store.state.showContactList = [adminUser, ...onlineUser, ...offlineUser], 'store.state.showContactList = [adminUser, ...onlineUser, ...offlineUser]')
        return (store.state.showContactList = [adminUser, ...onlineUser, ...offlineUser]);
        // console.log('如果不是管理员，则在列表内添加管理员账号',store.state.showContactList)
    }

}

/**
 * 处理对话到顶部
 * @param groupid
 */
export function handleConversationToTop(groupid) {
    console.log('置顶联系人')
    const {showContactList} = store.state;
    const idx = showContactList.findIndex(v => v.groupid === groupid);
    if (idx === -1) return;
    if (idx === 1) return;
    const user = showContactList.splice(idx, 1)[0];
    showContactList.splice(1, 0, user);
}
