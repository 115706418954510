import Vue from 'vue';
import VueRouter from 'vue-router';
import ChatView from '../views/Chat/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: ChatView,
  },
  {
    path: '/chatapp',
    name: 'chat',
    component: ChatView,
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/Video/index.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
