import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementLocale from 'element-ui/lib/locale';
import zh from './lang/zh';
import en from './lang/en';
import kh from './lang/kh';

import enLang from 'element-ui/lib/locale/lang/en';
import kmLang from 'element-ui/lib/locale/lang/km';
import zhLang from 'element-ui/lib/locale/lang/zh-CN';
Vue.use(VueI18n);

function getCookie(name) {
  var arr = document.cookie.split('; ');
  for (var i = 0, len = arr.length; i < len; i++) {
    var item = arr[i].split('=');
    if (item[0] === name) {
      return item[1];
    }
  }
  return '';
}

const locale = getCookie('think_language') || 'zh-cn';

const i18n = new VueI18n({
  locale, // set locale
  fallbackLocale: 'zh-cn',
  messages: {
    'zh-cn': zh,
    'en-us': en,
    'ca-bo': kh,
  },
});

if (locale === 'en-us') {
  elementLocale.use(enLang);
} else if (locale === 'ca-bo') {
  elementLocale.use(kmLang);
} else {
  elementLocale.use(zhLang);
}

export default i18n;
