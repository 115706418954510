<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    window.$vm = this;
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  min-width: 1200px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

img,
video {
  display: block;
  max-width: 100%;
  padding: 0;
  margin: 0;
  outline-width: 0;
  vertical-align: top;
}

@font-face {
  font-family: 'Avenir';
  src: url('~@/assets/font/Avenir.ttc');
}

a,
input,
button,
label,
select {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: none;
  text-decoration: none;
}

a {
  display: block;
  color: #191b26;
}

li {
  list-style: none;
}
</style>
