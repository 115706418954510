export default {
    lang: 'en',

    面试邀请: 'Interview Invitation',
    查看简历: 'View resume',
    发送Telegram号: 'Send Telegram number',
    请输入您的Telegram号: 'Please enter your Telegram number',
    请选择面试方式: 'Please choose the interview method',
    线下面试: 'Offline interview',
    视频面试: 'Video interview',
    请输入面试地点: 'Please enter the interview location',
    请输入备注说明: 'Please enter a note description',
    请选择简历: 'Please select resume',
    取消: 'cancel',
    投递简历成功: 'Successfully delivered resume',
    接受: 'accept',
    备注说明: 'Note description',
    确定: 'ok',
    拒绝: 'refuse',
    同意: 'agree',
    面试时间修改: 'Interview time modification',
    的Telegram账号: "'s Telegram account",
    复制账号: 'copy',
    复制成功: 'Copy succeeded',
    的简历: "'s resume",
    发送: 'send',
    // 提醒
    未连接请稍后再试: 'Not connected, please try again later',
    发送失败: 'Failed to send',
    格式不支持: 'Format not supported',
    您向对方发送了互换Telegram请求: 'you sent a Telegram swap request to the other party',
    已发送Telegram号: 'Send Telegram',
    您向对方发送了面试申请: 'You sent an interview application to the other side',
    您向对方发送了面试邀请: 'You sent an interview invitation to the other side',
    您已同意面试邀请: 'You have agreed to the interview invitation',
    你已拒绝视频面试邀请: 'You have declined the invitation to interview',
    您已接受对方修改面试时间: 'You have accepted the other party to modify the interview time',
    你已拒绝对方修改面试时间: 'You have refused to change the interview time',
    '[简历]': 'resume',
    '[互换Telegram]': '[Exchange Telegram]',
    '[申请面试]': '[Apply interview]',
    '[邀请面试]': '[Interview Invitation]',
    '[修改面试时间]': '[Modify interview time]',
    对方已同意您的面试邀请: 'The other side has agreed to your interview invitation',
    对方拒绝了您的面试邀请: 'The other side declined your interview invitation',
    对方已同意修改面试时间: 'The other side has agreed to modify the interview time',
    对方拒绝了修改面试时间: 'The other side refused to modify the interview time',
    // 视频部分
    岁: 'year old',
    您发起了视频邀请: 'Video invitation sented',
    对方已拒绝: 'The other party has declined',
    'id 不能为空': 'id cannot be empty',
    '你可以发一份简历给我吗?': 'Can you send me a resume? ',
    你需要先获取对方的资料: 'You need to get the contact information of the other party first ',
    马上获取: 'Get now',
    '申请面试将默认发送您的简历给对方!': 'The interview application will send your resume to the other party by default!',
    确认: 'confirm',
    您已获取对方的联系方式: 'You have obtained the contact information of the other party',
    对方已获取您的联系方式: 'The other party has obtained your contact information:',
    请先完成引导: 'Please complete the guide process first',
    正在咨询: 'Consulting',

    对方已同意您的面试申请: 'The other side <span class="green">agreed</span> to your interview application',
    对方已拒绝您的面试申请: 'The other side <span class="red">refused</span> your interview application',

    '[视频面试邀请]': '[Video Interview Invitation]',
    '[联系方式]': '[Contact Information]',
    '[语音消息]': '[Voice Message]',

    您已同意对方面试申请: "You agreed to the other side's interview application",
    您已拒绝对方面试申请: "You refused the other side's interview application",
    登录: 'Log in',

    对方向您发送了互换Telegram请求: 'The other side sent you a request to exchange Telegram',
    您已拒绝与对方互换Telegram: 'You refused to exchange Telegram with the other side',
    对方已拒绝与您互换Telegram: 'The other side refused to exchange Telegram with you',
    已拒绝: 'Refused',
    已同意: 'Have agreed',

    管理员: 'Administrator',
    对方无应答: 'No answer from the other side',
    对方已取消: 'The other side has cancelled',
    已取消: 'Cancelled',
    呼叫超时: 'Overtime Call',
    通话时长: 'Duration {duration}',
    '提示: 只有当对方回复后, 才能继续聊天': 'Note: Only after the other side replies, the chatting can be continued',
    '或者您也可以:': 'Or you can also:',
    获取对方的联系方式: "Get the other side's contact information",
    id不能为空: 'id cannot be empty',

    搜索会话: 'Search conversation',
    删除会话: 'Deleted Chats',
    在这里输入: 'Enter here',
    查看详情: 'View details',

    姓名: 'Name',
    微信: 'WeChat',
    手机: 'Phone',
    邮箱: 'E-mail',

    我的Telegram为: 'My Telegram is',
    // 面试

    // 预约面试
    面试主题: 'Interview subject',
    请输入面试主题: 'Please enter the interview subject',
    面试时间: 'Interview time',
    面试方式: 'Interview method',
    面试地点: 'Interview location',
    选择日期时间: 'Select Date and Time',
    // 沟通职位
    请选择要沟通的职位: 'Please select the position to communicate with',

    // 选择简历
    选择简历: 'Select the resume',
    更新: 'Update',
    完成度: 'Percentage completed',

    // 视频通话
    视频邀请: 'Video invitation',
    工作面试: 'Job interview',
    我: 'Me',
    对方未开启摄像头: 'The other side does not have the camera on',
    视频通话结束: 'Video interview ends',
    企业中心: 'Enterprise center',

    // 面试评价
    面试评价: 'Interview evaluation',
    你觉得面试怎么样: 'What did you think of the interview',
    评价成功: 'Evaluation succeeded',
    '我们已经收到您的反馈, 感谢您的耐心填写, 期待您找到好工作': 'We have received your feedback, thank you for your patience, hope you find a good job',
    返回首页: 'Return to home page',
    返回个人中心: 'Return to user center',
    保存: 'Save',
    关闭: 'Close',

    预览: 'Preview',
    邀请您面试: '{contact} invites you for an interview',
    您邀请面试: 'You invited {name} {type} for interview',
    视频: 'Video',
    线下: 'Offline',
    '您好, 请问可以在线视频面试吗 ?': 'Hello, can you have a video interview?',
    默认: 'Default',
    简介: 'Introduction',
    历史记录: 'History',
    薪资: 'Salary',
    工作经验: 'Work experience',
    学历: 'Education',
    语言: 'Language',
    工作内容: 'Job description',
    暂无工作经验: 'No work experience',
    自我评价: 'Self-evaluation',
    在线: 'Online',
    月: 'Month',
    咨询: 'Consultation',

    // 补充
    暂无简历: 'No resume ',
    通话已挂断: 'Call hung up',
    您已同意对方的面试邀请: 'You have <b class="green">accepted</b> the interview invitation, {d} days {h} hours remaining until the agreed interview time',
    您已拒绝对方的面试邀请: 'You have <b class="red">rejected</b> the interview invitation.',
    新消息提醒: 'new message',

    我想要申请一次面试机会: 'I want to apply for an interview',
    请先取消隐藏在线简历: 'Please unhide the online resume first',
    对方操作后会有提示回执: "The other party's operation will prompt us",
    收到线上面试: '<span class="green">{name}</span>，invites you to conduct an online video interview at <span class="green">{h}:{m} on {d}.{M}.{Y}</span>，do you accept it？',
    收到线下面试: '<span class="green">{name}</span>，invite you to conduct interview at <span class="green">{h}:{m} on {d}.{M}.{Y}</span> at <span class="green">{address}</span>，do you accept it？',

    发起线上面试: 'You have invited " <span class="green">{name}</span> " for an online video interview at   <span class="green">{y}.{M}.{d} {h}:{m}</span>。',
    发起线下面试: 'You have invited "<span class="green">{name}</span>" for an interview in <span class="green">{y}.{M}.{d} {h}:{m}</span>，on <span class="green">{address}</span>。',
    您还没有编辑在线简历: "You haven't finished your online resume",
    知道了: 'Next time',
    去编辑: 'Do now',
    联系人: 'Person to contact',
    联系电话: 'Contact number',

    请选择身份: 'Choose your identity',
    我是企业: 'Employer',
    我是求职者: 'Job seeker \n'
};
