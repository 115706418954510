import i18n from '@/locale';
import {postAjaxGetTokenInfo, postGetJob, postGetResume, postMemberAvatars} from '@/services';
import store from '@/store';
import {Message} from 'element-ui';
import {leaveRoom} from '../agora/init';
import {beginConversation, getGroupIDByConfig, initConversationList} from './conversation';
import {
    sendAckVideoMsg,
    sendAnswerCallMsg,
    sendConfirmCalleeMsg2,
    sendConfirmRingMsg,
    sendInviteVideoMsgByConfig,
    sendTextMsg,
    sendTgMsg
} from './msg';
import {subscribeUsers} from './tools';
import {$get} from '@/store/ls';


/**
 * 初始化
 * @param conn
 * @param reconnect
 * @param autoSelect
 * @param config
 * @param fn
 * @returns {*}
 */
export const initEvent = (conn, reconnect, autoSelect, config = {}, fn) =>
    conn.listen({
        async onOpened() {
            console.log('登入成功11');
            if (fn) fn();
            conn.context.isConnected = true;
            store.commit('updateState', {
                isLogin: true,
            });
            const [{data: originUserList}, {data: _sessionList}] = await Promise.all([
                // 列出某用户加入的所有群组。
                conn.getJoinedGroups({
                    pageNum: 1,
                    pageSize: 1000,
                }),
                conn.getSessionList(),
            ]);
            const userMap = {};
            const groupUserMap = {};
            const sessionMetaMap = {};
            const {isBoss, currentGroup, admin, me} = store.state;
            let totalUnread = 0;
            console.log('originUserList', originUserList, '_sessionList', _sessionList);
            if (_sessionList)
                _sessionList.channel_infos.forEach(({meta, unread_num}) => {
                    // const { payload, timestamp } = v.meta;
                    const {to, from} = JSON.parse(meta.payload);
                    if (to === me.user) {
                        // 单聊
                        sessionMetaMap[from] = {unread_num, ...meta};
                    } else {
                        sessionMetaMap[to] = {unread_num, ...meta};
                    }
                });
            originUserList.forEach(v => {
                const group = JSON.parse(v.groupname);
                v.to = isBoss ? group.talent + '-2' : group.boss + '-1';
                v.group = group;
                userMap[v.to] = true;
                groupUserMap[v.groupid] = {
                    uid: v.to.split('-')[0],
                    ...group,
                };
                v.meta = sessionMetaMap[v.groupid];
                v.unread_num = (sessionMetaMap[v.groupid] && sessionMetaMap[v.groupid].unread_num) || 0;
                if (sessionMetaMap[v.groupid]) {
                    totalUnread += v.unread_num;
                }
            });

            let userInfoMap = {};
            const userStatus = {};
            const [_userInfoMap, {result = []}] = await Promise.all([
                postMemberAvatars([...Object.keys(userMap), store.state.me.user, store.state.admin.uid]),
                subscribeUsers([...Object.keys(userMap), admin.uid]),
            ]);
            Object.keys(_userInfoMap).forEach(v => {
                userInfoMap[v] = _userInfoMap[v];
            });
            console.log('result...', result);

            for (const {uid, status} of result) {
                if (uid && !Array.isArray(status)) {
                    const [_uid] = uid.split('-');
                    userStatus[uid] = status;
                }
            }

            originUserList.unshift({
                groupid: admin.uid,
                to: admin.uid,
                unread_num: (sessionMetaMap[admin.uid] && sessionMetaMap[admin.uid].unread_num) || 0,
            });
            if (sessionMetaMap[admin.uid] && sessionMetaMap[admin.uid].unread_num) {
                totalUnread += sessionMetaMap[admin.uid];
            }
            localStorage.setItem('UNREAD_MSG', totalUnread);
            userInfoMap[admin.uid] = {
                uid: admin.uid,
                nickname: i18n.t('管理员'),
            };
            const msgStatus = $get('IM_OPERATIVE_MSG') || {};
            store.commit('updateState', {
                userStatus,
                userInfoMap,
                originUserList,
                groupUserMap,
                showContactList: originUserList,
                msgStatus,
            });
            const _ul = initConversationList();
            const {t} = $vm.$route.query;
            if (!t && autoSelect && currentGroup === '' && _ul.length > 0) {
                store.dispatch('changeConversation', _ul[0].groupid);
            }

            console.log('conn open... config', config);

            if (t) {
                const {data: {jobInfo, resumeInfo, uid} = {}} = (await postAjaxGetTokenInfo(t)) || {};
                if (jobInfo) {
                    await beginConversation({
                        uid,
                        jobName: jobInfo.jobs_name,
                        jobsId: jobInfo.id,
                        resume_id: (resumeInfo && resumeInfo.id) || '100',
                    });
                }
            } else if (config.text) {
                const groupid = await getGroupIDByConfig(config);
                await sendTextMsg(groupid, config.text);
                localStorage.setItem('IM_SEND_HELLO_SUCCESS', 1);
            } else if (config.reject) {
                const {groupid} = JSON.parse(localStorage.getItem('IM_VIDEO_DATA'));
                sendAnswerCallMsg(groupid, {}, 'refuse');
            } else if (config.call) {
                const groupid = await getGroupIDByConfig(config);
                await sendInviteVideoMsgByConfig(groupid, config);
                window.open('/chatapp/video?v=1', 'interview_page');
            }
        },
        async onClosed(message) {
            console.log('已关闭');
            conn.context.isConnected = false;
            store.commit('updateState', {isLogin: false});
            if (reconnect) {
                await new Promise(resolve => setTimeout(resolve, 2000));
                await conn.open(store.state.me);
            }
        },

        /**
         * 在线状态更改时
         * @param msg
         */
        onPresenceStatusChange(msg) {
            console.log('onPresenceStatusChange', msg);
            const {isBoss} = store.state;
            for (let {userId, statusDetails} of msg) {
                if (!userId.includes('-')) userId = isBoss ? userId + '-2' : userId + '-1';
                store.state.userStatus[userId] = statusDetails[0];
                store.state.reloadIdx++;
            }
        },


        // 收到文本消息
        onTextMessage: function (res) {
            console.log('收到文本消息', res);
            if (res.from != store.state.admin1.uid) {
                console.log('收到非管理员的消息')
            } else {
                console.log('收到管理员的消息')
            }
            store.dispatch('addMsg', res);
        },
        // 收到表情消息
        onEmojiMessage: function () {
            Message({
                message: '还不支持',
                type: 'warning',
            });
        },
        // 收到图片消息
        onPictureMessage: function (res) {
            console.log('图片消息：', res);
            store.dispatch('addMsg', res);
        },
        // 收到命令消息
        onCmdMessage: function (res) {
            console.log('命令消息：', res);
            const {ext, from, to} = res;
            const {action, callId, calleeDevId, callerDevId, result} = ext;
            const {userInfoMap, me, isBoss} = store.state;
            // 如果是收到的消息
            if (action === 'confirmRing' || action === 'alert') {
                const {resume, job, ext} = JSON.parse(localStorage.getItem('IM_VIDEO_DATA'));
                localStorage.setItem(
                    'IM_VIDEO_DATA',
                    JSON.stringify({
                        resume,
                        job,
                        groupid: to,
                        uid: from,
                        ext: {callId, calleeDevId, callerDevId, channelName: ext.channelName},
                        user: userInfoMap[from] || {},
                    })
                );
            }
            if (isBoss && action === 'alert') {
                console.log('------------alert');
                // 企业端收到
                sendConfirmRingMsg(to, ext);
            }
            if (!isBoss && action === 'confirmRing') {
                store.state.hasVideoCall = true;
                localStorage.setItem('IM_HAS_CALL', '1');
            }
            if (isBoss && action === 'answerCall') {
                if (result === 'refuse') {
                    // 企业端收到拒绝消息
                    if (store.state.isVideoPage) {
                        clearTimeout(store.state.callTimer);
                        leaveRoom();
                    }
                } else {
                    console.log('------------answerCall', to, ext);
                    sendConfirmCalleeMsg2(to, ext);
                }
            }
            if (action === 'confirmCallee1') {
                localStorage.removeItem('IM_HAS_CALL');
                store.state.hasVideoCall = false;
                if (store.state.isVideoPage) leaveRoom();
            }
            if (!isBoss && (action === 'cancelRing' || action === 'timeout')) {
                localStorage.removeItem('IM_HAS_CALL');
                store.state.hasVideoCall = false;
            }
            store.dispatch('addMsg', res);
        },
        // 收到音频消息
        onAudioMessage: function (res) {
            console.log('音频消息:', res);
            store.dispatch('addMsg', res);
        },
        // 收到位置消息
        onLocationMessage: function () {
        },
        // 收到文件消息

        onFileMessage: function () {
        },
        // 收到自定义消息
        async onCustomMessage(res) {
            console.log('自定义消息', res);
            const {customEvent, customExts, from, to, ext} = res;
            if (customEvent === 'sendTelegram' && from !== store.state.me.user) {
                // const { deviceId, sended } = customExts;
                // if (deviceId === 'web' && sended === '1') {
                //   if (location.pathname.startsWith('/chatapp/chatapp')) sendTgMsg(to, localStorage.getItem('tg'), '0', 'web');
                // }
            }
            if (customEvent === 'inviteVideo') {
                let {jobId: job_id, members} = ext.ext;
                const [{resumeId: resume_id}] = JSON.parse(members);
                const [currentJob, currentResume] = await Promise.all([postGetJob({job_id}), postGetResume({resume_id})]);
                localStorage.setItem(
                    'IM_VIDEO_DATA',
                    JSON.stringify({
                        resume: currentResume,
                        job: currentJob,
                        groupid: to,
                        uid: from,
                        ext: {
                            callId: ext.callId,
                            calleeDevId: ext.calleeDevId,
                            callerDevId: ext.callerDevId,
                            channelName: ext.channelName,
                        },
                        user: store.state.userInfoMap[from] || {},
                    })
                );
                sendAckVideoMsg(to, ext);
            }
            store.dispatch('addMsg', res);
        },
    });
