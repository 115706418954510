import Vue from 'vue';
import Vuex from 'vuex';
import {getHistoryMsg, handleMsg, subscribeUsers, unreadMsgChange} from '@/assets/js/easemob/tools';
import {getAjaxResumeGet, postGetJob, postGetResume} from '@/services';
import {ackChannelMsg, ackManagerMsg} from '@/assets/js/easemob/msg';
import {handleConversationToTop, initConversationList} from '@/assets/js/easemob/conversation';
import {conn} from "@/assets/js/easemob/init";
import {
    getConversationlist_admin,
    getHistoryMessages_admin,
    otherUpdateMessage,
    scrollToLastMessage
} from "@/assets/js/easemob/admin";


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        me: {},
        isLogin: false,
        isBoss: false,
        userStatus: {},
        userInfoMap: {},
        originUserList: [],
        searchText: '',
        showContactList: [],
        showContactList_admin: [], //管理员的会话列表
        showContactList_admin_avatar: [], //管理员的会话列表(头像)
        showContactList_admin_avatar_: [], //管理员的会话列表(头像)
        currentGroup: '',
        // 当前简历
        currentResume: {},
        currentJob: {},
        groupMsg: {},
        groupUserMap: {},

        jobList: [],
        resumeList: [],

        reloadIdx: 0,

        hasVideoCall: false,
        downloadPage: '',
        disableInput: true,
        msgStatus: {},

        // 声网部分
        isVideoPage: false,
        options: {
            appid: '2151a472c43a4720895e004664ea68c0',
            channel: '',
            uid: '',
            token: '',
        },
        remoteUser: {},
        remoteUserVolume: 0,
        duration: 0,
        localTracks: {
            videoTrack: {},
            audioTrack: {},
        },
        trackState: {
            videoTrackMuted: false,
            audioTrackMuted: false,
            remoteAudioTrackMuted: false,
            remoteVideoTrackMuted: false,
        },
        // 企业端 : 0 已发起语音 1 已挂断  3 求职端接通后挂断
        // 2 接通中
        // 求职端 : 4 面试评价 5 评价完成
        videoStatus: '0',
        agora: {
            channel: '',
            accessToken: '',
            agoraUserId: '',
        },
        callTimer: null,
        isContact: false,

        //环信管理员部分
        /**
         * 测试环境：
         1，企业端客服：
         电话：81697501
         uid：2176
         2，求职端客服：
         电话：15697501
         uid：363440

         生产环境：
         1，企业端客服：
         电话：81697501
         uid：438121
         2，求职端客服：
         电话：15697501
         uid：399682
         */
        admin: {
            uid: "2176-1" || "363440-1" || "438121-1" || "399682-1",
            unread_num: 0,
        },
        admin1: {
            uid: "2176-1" || "363440-1" || "438121-1" || "399682-1",
            unread_num: 0,
        },
        messageList: [],//管理员消息收到的消息列表
        target_admin: {},//管理员当前聊天的对方

        isClick: false,//普通用户是否主动切换过联系人

        otherUserTarget: '',//普通用户当前选择的联系人
    },
    mutations: {
        updateOtherUserTarget(state, id) {
            state.otherUserTarget = id
        },
        updateIsClick(state, string) {
            state.isClick = string
        },
        updateAdmin1(state, string) {
            state.admin1 = string
        },
        updateAdmin(state, string) {
            state.admin = string
        },

        updateState(state, payload) {
            for (const key in payload) {
                state[key] = payload[key];
            }
        },

        updateState1(state, key, value) {
            state.groupMsg[key] = value
        },
        /**
         * 搜索联系人
         * @param state
         * @param payload
         */
        searchContact(state, payload) {
            console.log('搜索联系人', state, payload)
            if (!payload) state.showContactList = state.originUserList;
            const {userInfoMap} = state;
            const uidList = {};
            Object.keys(userInfoMap).forEach(uid => {
                const {nickname, uid: _uid} = userInfoMap[uid];
                if (nickname.includes(payload) || _uid.includes(payload)) uidList[uid] = true;
            });
            state.showContactList = state.originUserList.filter(v => uidList[v.to]);
            state.searchText = payload;
        },


        /**
         * 管理员账号更新会话列表
         */
        updateShowContactList_admin(state, list) {
            state.showContactList_admin = list
        },
        updateShowContactList_admin_avatar(state, list) {
            state.showContactList_admin_avatar = list
        },
        updateShowContactList_admin_avatar_(state, list) {
            state.showContactList_admin_avatar_ = list
        },


        /**
         * 非管理员账号更新会话列表
         */
        updateShowContactList(state, list) {
            state.showContactList = list
        },

        /**
         * 更新管理员消息列表
         */
        updateMessageList_admin(state, list) {
            state.messageList = list
        },

        /**
         * 管理员账号更新当前聊天对象
         */
        updateTarget_admin(state, obj) {
            state.target_admin = obj
        },

        /**
         * 控制管理员输入框输入
         */
        updateInput_admin(state, boolean) {
            state.disableInput = boolean
        },

        /**
         * 收到管理员消息时更新列表
         */
        updateMessageFromAdmin(state, obj) {
            state.groupMsg = obj
        },

        /**
         * 收到管理员消息时更新
         */
        updateGroupUserMap(state, obj) {
            state.groupUserMap = obj
        },

        //非管理员账号切换联系人
        updateCurrentGroup(state, obj) {
            state.currentGroup = obj
        },
    },

    actions: {

        /**
         * 订阅用户
         * @param state
         * @param commit
         * @param users
         * @returns {Promise<void>}
         */
        async subscribeUsers({state, commit}, users) {
            if (!users || !users.length) return;
            const {userStatus, isBoss} = state;
            console.log('preview...', users);
            const userSet = new Set();
            users.map(v => {
                if (!v) return;
                if (!v.includes('-')) {
                    userSet.add(isBoss ? v + '-2' : v + '-1');
                } else {
                    userSet.add(v);
                }
            });
            if (users.length <= 0) return;
            const {result} = await subscribeUsers(Array.from(userSet));
            console.log(result, '消息记录');
            if (!result) return;
            for (const {uid, status} of result) {
                if (uid && !Array.isArray(status)) {
                    const [_uid] = uid.split('-');
                    userStatus[uid] = status;
                }
            }
            commit('updateState', {userStatus});
        },

        /**
         * 改变对话联系人，非管理员
         * @param state
         * @param commit
         * @param dispatch
         * @param groupid
         * @returns {Promise<void>}
         */
        async changeConversation({state, commit, dispatch}, groupid) {
            const {groupUserMap, groupMsg} = state;
            //TODO:
            console.log(groupMsg, 'groupMsg')
            console.log(groupMsg[groupid], 'groupMsg[groupid]')
            let key = Object.keys(state.groupMsg)
            console.log(key, 'state.groupMsg里面的key')
            if (key.includes(state.admin1.uid)) {
                if (groupid == state.admin1.uid) {
                    // state.groupMsg[groupid] = []
                    // delete groupMsg[groupid]
                    console.log('切换到联系人，清空之前的消息')
                } else {
                    console.log('切换的不是管理员')
                }
            } else {
                console.log('store内还没有数据')
            }
            console.log('切换联系人', groupid, 'groupidgroupidgroupid')
            console.log(state, commit, dispatch, groupid)

            dispatch('ackChannel', groupid);
            state.currentGroup = groupid;
            const {uid, jobsId: job_id, resume_id} = groupUserMap[groupid] || {};
            const awaitEvent = [getHistoryMsg(groupid, groupMsg[groupid])];
            // const awaitEvent = await [getHistoryMsg(groupid, groupMsg[groupid])];
            console.log(awaitEvent, 'awaitEvent')
            if (uid && job_id) {
                awaitEvent.push(postGetResume({uid, resume_id}));
                awaitEvent.push(postGetJob({job_id}));
                awaitEvent.push(getAjaxResumeGet(resume_id, uid));
            }
            const [msgs, currentResume, currentJob, downloadPage] = await Promise.all(awaitEvent);
            console.log('fetchHistoryMessages...', msgs);
            commit('updateState', {currentResume, currentJob, downloadPage});
            if (!groupMsg[groupid]) groupMsg[groupid] = [];
            handleMsg(msgs, groupMsg[groupid]);
            commit('updateIsClick', true)
        },

        async ackChannel({state}, groupid) {
            console.log(state, groupid)
            const {showContactList} = state;
            console.log(showContactList, 'showContactList')
            const group = showContactList.find(v => v.groupid === groupid);
            if (!group || !group.unread_num) return;
            unreadMsgChange(group.unread_num * -1);
            group.unread_num = 0;
            ackChannelMsg(groupid);
        },

        /**
         * 添加消息
         * @param state
         * @param dispatch
         * @param msg
         */
        addMsg({state, commit, dispatch}, msg) {
            console.log('添加消息', msg)
            //管理员添加消息
            if (msg.to == state.admin1.uid) {
                console.log('管理员添加消息')
                getConversationlist_admin().then(res => {
                    console.log(res, '管理员收到消息时更新列表成功')
                    console.log('state.target_admin', state.target_admin)
                    console.log('state.showContactList_admin_avatar', state.showContactList_admin_avatar)
                    let arr = []
                    state.showContactList_admin_avatar.map(item => {
                        arr.push(item.uid)
                    })
                    console.log(arr, '所有的会话人员id1')
                    if (arr.includes(msg.from)) {
                        //更新当前对话的联系人
                        console.log('更新当前对话的联系人')
                        window.sessionStorage.setItem('isAddMessage', 'true')
                        getHistoryMessages_admin(state.target_admin)
                    } else {
                        console.log('新添加列表')
                        window.sessionStorage.setItem('isAddMessage', 'false')
                    }
                }).catch(err => {
                    console.log(err, '管理员收到消息时更新列表失败')
                })
                initConversationList()
                unreadMsgChange()
            }
            //非管理员添加消息
            else {
                console.log('非管理员添加消息')
                //收到管理员发来的消息
                if (msg.from == state.admin1.uid) {
                    console.log('收到管理员发来的消息', msg, state.target_admin)
                    // console.log(state.groupMsg, 'state.groupMsg')
                    // console.log(state.currentGroup, 'state.currentGroup')
                    // let obj = state.groupMsg
                    // console.log(obj, 'objobjobjobj')
                    // msg.isLeft = true
                    // obj[state.currentGroup].push(msg)
                    // console.log(obj, '非管理员添加消息之后2')
                    // state.groupMsg = obj
                    // // clickContact(state.admin1.uid)
                    // let list = state.showContactList
                    // list[0] = {
                    //     groupid: state.admin1.uid,
                    //     to: state.admin1.uid,
                    //     unread_num: 0
                    // }
                    // console.log(list, 'listlist')
                    // commit('updateShowContactList', list)
                    // let item = {
                    //     groupid: msg.from,
                    //     to: msg.from,
                    //     unread_num: 0
                    // }
                    // let objobj = JSON.parse(JSON.stringify(state.groupMsg))
                    //
                    // //如果在当前发来消息的联系人会话中
                    // if (msg.from == state.otherUserTarget) {
                    //     console.log('更新当前视图')
                    //     objobj[msg.from] = state.groupMsg
                    //     console.log(state.groupMsg, 'state.groupMsg')
                    //     commit('updateGroupUserMap', objobj)
                    //     setTimeout(() => {
                    //         scrollToLastMessage()
                    //     }, 100)
                    // } else {
                    //     // console.log('其他人发来的会话，不更新视图')
                    //     // console.log(state.groupMsg, 'state.groupMsg12')
                    //     // if (state.groupMsg[msg.from]) {
                    //     //     state.groupMsg[msg.from].push(msg)
                    //     // }
                    //     // console.log(state.groupMsg, 'state.groupMsg12')
                    //
                    //     //////////////////////////////////////
                    //     const {originUserList, groupMsg, currentGroup, me} = state;
                    //     console.log(originUserList, groupMsg, currentGroup, me)
                    //     let {to, ..._msg} = msg;
                    //     console.log(to, msg)
                    //     if (to === me.user) to = msg.from;
                    //     // originUserList.map(item => {
                    //     //     console.log(item.groupid)
                    //     // })
                    //     const group = originUserList.find(v => Number(v.groupid) === Number(to));
                    //     console.log(group,'groupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroupgroup')
                    //     group.unread_num++;
                    //     if (currentGroup === to) dispatch('ackChannel', to);
                    //     if (!groupMsg[to]) groupMsg[to] = [];
                    //     handleMsg([_msg], groupMsg[to], true);
                    //     unreadMsgChange();
                    //     handleConversationToTop(to);
                    //     /////////////////////////////////////////
                    // }
                    // initConversationList()


                    console.log('收到管理员发来的消息11')
                    const {showContactList, originUserList, groupMsg, currentGroup, me} = state;
                    console.log(originUserList, 'originUserList')
                    console.log(groupMsg, 'groupMsg')
                    console.log(currentGroup, 'currentGroup')
                    console.log(me, 'me')
                    let {to, ..._msg} = msg;
                    console.log(to, msg)
                    if (to === me.user) {
                        to = msg.from
                    }
                    showContactList.map(item => {
                        console.log(item.groupid, 'showContactListshowContactListshowContactListshowContactList')
                    })
                    console.log(to, 'Number(to)')
                    const group = showContactList.find(v => v.groupid === to);
                    console.log(group)
                    group.unread_num++;
                    if (currentGroup === to) dispatch('ackChannel', to);
                    if (!groupMsg[to]) groupMsg[to] = [];
                    handleMsg([_msg], groupMsg[to], true);
                    unreadMsgChange();
                    handleConversationToTop(to);
                }
                //收到其他用户发来的消息
                else {
                    console.log('收到其他用户发来的消息')
                    const {originUserList, groupMsg, currentGroup, me} = state;
                    console.log(originUserList, groupMsg, currentGroup, me)
                    let {to, ..._msg} = msg;
                    console.log(to, msg)
                    if (to === me.user) to = msg.from;
                    originUserList.map(item => {
                        console.log(item.groupid)
                    })
                    const group = originUserList.find(v => Number(v.groupid) === Number(to));
                    if (group) {
                        console.log('之前列表内有该联系人')
                        console.log(group)
                        group.unread_num++;
                        if (currentGroup === to) dispatch('ackChannel', to);
                        if (!groupMsg[to]) groupMsg[to] = [];
                        handleMsg([_msg], groupMsg[to], true);
                        unreadMsgChange();
                        handleConversationToTop(to);
                    } else {
                        console.log('之前列表内没有该联系人')
                        // if (window.location.href.indexOf('/chatapp/chatapp') > -1) {
                            window.location.reload()
                        // }
                    }
                }
            }
        },
    },
    modules: {},
});
