import axios from 'axios';
import { Message } from 'element-ui';

axios.defaults.withCredentials = true;
let baseURL;
if (process.env.NODE_ENV === 'development') {
  baseURL = process.env.VUE_APP_SERVER;
} else {
  baseURL = location.origin;
}

export const api = axios.create({
  withCredentials: true,
  baseURL,
});

api.interceptors.request.use(config => {
  return config;
});

api.interceptors.response.use(res => {
  const { data } = res;
  if (data.status === 1) {
    return data.data || data || true;
  } else if (data.status === 0) {
    if (data.msg) {
      Message({
        message: data.msg,
        type: 'error',
      });
    }
    console.log(res);
    return false;
  } else if (res.status === 200) {
    if (data && data.data && data.data.show_footer === 1) {
      return data.msg;
    } else {
      return false;
    }
  }
});

export const agoraApi = axios.create({
  baseURL: '',
});
