import { beginConversation, handleConversationToTop, initConversationList } from '@/assets/js/easemob/conversation';
import { initIM } from '@/assets/js/easemob/init';
import {
    sendAcceptMsg,
    sendAnswerCallMsg,
    sendApplyInterviewMsg,
    sendImgMsg,
    sendImgMsgToAdmin,
    sendInviteInterviewMsg,
    sendInviteVideoMsg,
    sendResumeMsg,
    sendSwapTgMsg,
    sendTextMsg, sendTextMsg_admin,
    sendTextMsgToAdmin,
    sendTgMsg,
} from '@/assets/js/easemob/msg';
import {
    getJobLists,
    getResumeLists,
    postInterviewAdd,
    postJobViewLog,
    getResumeDownload,
    postResumeViewLog,
    getAjaxResumeGet,
    getOnlineResumeLists,
    postMemberAvatars
} from '@/services';
import dayjs from 'dayjs';
import { Message } from 'element-ui';
import { mapState } from 'vuex';
import { $get, $set } from '@/store/ls';
import { api } from "@/services/http";
import { conn } from "@/assets/js/easemob/init";
import { getHistoryMessages_admin } from "@/assets/js/easemob/admin";
import store from "@/store";
// import websdk from "_easemob-websdk@4.1.1@easemob-websdk";
// import { config as webimConfig } from "@/assets/js/easemob/config";
// import i18n from "@/locale";

export default {
    data() {
        return {
            isConsulting: true,
            text: '',
            historyList: [],

            hasDialog: false,

            getResumeModal: false,
            confirmSendResumeModal: false,
            toEditResumeModal: false,

            onlineResumeList: [],

            // tg 相关
            tgModal: false,
            tg: '',
            sendType: '',
            deviceId: '',
            currentSwapMsg: {},

            // 面试邀请相关
            interviewConfirmModal: false,
            interviewModal: false,
            interviewForm: {
                jobs_name: '',
                title: '', // 职位名称
                jobs_id: '', // 职位id
                resume_id: '', // 简历id
                interview_time: '',
                address: '',
                notes: '',
                type: '', // 0 线下面试 1 视频面试
                contact: '',
                telephone: '',
            },

            // 发起会话相关
            jobsModal: false,
            resumeModal: false,
            uid: '',
            coption: {},

            // 简历展示相关
            sendResumeModal: false,

            // 视频通话相关
            videoReceiveModal: false,
            videoData: {},
            cameraStatus: true,
            videoTimer: null,
            disableInput_admin: false,

            shpwTootip: false,

            showMain: false,
            show: false,
            tabIndex: 0,
            adminAvatar: require('@/assets/img/CamboJob@2x.png'),

            listIndex: 0,//选择的会话列表人员index
            showTarget: false,
            showTooltip: false,

            showDelete:-1,
        };
    },
    computed: {
        ...mapState([
            'isBoss',
            'userStatus',
            'userInfoMap',
            'showContactList',
            'showContactList_admin',
            'showContactList_admin_avatar',
            'originUserList',
            'currentGroup',
            'groupMsg',
            'reloadIdx',
            'currentResume',
            'currentJob',
            'jobList',
            'resumeList',
            'me',
            'disableInput',
            'downloadPage',
            'groupUserMap',
            'isLogin',
            'hasVideoCall',
            'admin',
            'msgStatus',
            "messageList",
            "target_admin",
        ]),
        searchText: {
            get() {
                return this.$store.state.searchText;
            },
            set(v) {
                // if (store.state.me.nickname == '游客' || store.state.me.nickname == 'Guest' || store.state.me.nickname == 'ភ្ញៀវ') {
                if (store.state.me.user.indexOf('g_') > -1) {
                    console.log('游客搜索')
                    return
                }
                if (store.state.me.user == '363440-1' || store.state.me.user == '2176-1' || store.state.me.user == '438121-1' || store.state.me.user == '399682-1') {
                    console.log('管理员账号搜索')
                    // this.$store.commit('searchContact_admin', v)
                    console.log('搜索联系人', store.state, v)
                    if (!v) {
                        // initConversationList()
                        store.commit('updateShowContactList_admin_avatar', store.state.showContactList_admin_avatar_);
                    } else {
                        let listlist = []
                        store.state.showContactList_admin_avatar_.map(item => {
                            if (item.nickname.indexOf(v) > -1) {
                                listlist.push(item)
                            }
                        })
                        console.log(listlist, '搜索后的数据5')
                        console.log(store.state.showContactList_admin_avatar, '搜索前的数据5')
                        // console.log('等待5秒')
                        // setTimeout(() => {
                        // store.commit('updateShowContactList_admin', listlist);
                        store.commit('updateShowContactList_admin_avatar', listlist);

                        // }, 5000)

                        // state.showContactList_admin_avatar = list
                    }
                    store.state.searchText = v;
                } else {
                    console.log('用户搜索')
                    this.$store.commit('searchContact', v);
                }

            },
        },
    },
    watch: {
        reloadIdx() {
            this.sendResumeModal = false;
            this.toBottom();
        },
        hasVideoCall(val) {
            if (val) {
                this.videoReceiveModal = true;
                this.videoData = JSON.parse(localStorage.getItem('IM_VIDEO_DATA'));
                clearInterval(this.videoTimer);
                this.videoTimer = setInterval(() => {
                    const hasCall = $get('IM_HAS_CALL');
                    if (!hasCall) {
                        this.videoReceiveModal = false;
                        clearInterval(this.videoTimer);
                    }
                });
            } else {
                this.videoReceiveModal = false;
                this.videoData = {};
            }
        },
    },
    methods: {
        //显示删除联系人弹出框
        clickRight(index){
            this.showDelete = index
            console.log('右键点击')
            //监听滚动
            let that = this
            let ele = document.querySelector('.left-main')
            ele.addEventListener('scroll',function (){
                console.log('联系人滚动')
                that.showDelete = -1
            })
        },
        closeWindow() {
            window.close()
        },
        confirm_() {
            console.log('选择游客选择的管理员')
            console.log(this.tabIndex)
            let value = {
                uid: '',
                unread_num: 0
            }
            let list = []
            if (window.location.href.indexOf('test.cambojob.com') > -1) {
                if (this.tabIndex == 0) {
                    value.uid = "2176-1";
                    list = [{ groupid: "2176-1", to: "2176-1", unread_num: 0 }]
                } else {
                    value.uid = "363440-1";
                    list = [{ groupid: "363440-1", to: "363440-1", unread_num: 0 }]
                }
            }else{
                if (this.tabIndex == 0) {
                    value.uid = "438121-1";
                    list = [{ groupid: "438121-1", to: "438121-1", unread_num: 0 }]
                } else {
                    value.uid = "399682-1";
                    list = [{ groupid: "399682-1", to: "399682-1", unread_num: 0 }]
                }
            }
            store.commit('updateAdmin1', value)
            store.commit('updateAdmin', value)
            store.commit('updateShowContactList', list)
            console.log(store.state.admin1)
            this.showMain = true
            window.localStorage.setItem('selectRule', 'true')
            // initConversationList()
        },

        /**
         * 右键
         */
        rightClick() {
            console.log('右键点击')
            this.shpwTootip = true
            // this.shpwTootip = true

            // this.$set(this.shpwTootip,false);
        },

        /**
         * 联系人置顶
         * @param item
         */
        toTOP(item) {
            console.log(item)
            let { groupid } = item
            console.log(groupid, '需要置顶该联系人')
            handleConversationToTop(groupid);
        },

        /**
         * 删除联系人
         * @param item
         */
        deleteItem(item) {
            if (item.groupid == '363440-1' || item.groupid == '2176-1'|| item.groupid == '438121-1'|| item.groupid == '399682-1') {
                this.$message.info('不能删除管理员')
                return
            }
            console.log(item)
            console.log('删除联系人  非管理员 ,3')
            console.log(this.$store.state.originUserList)
            let { groupid } = item
            //删除的是群聊
            conn.deleteConversation({
                // channel: item.to,
                // chatType: 'singleChat',

                channel: groupid,
                chatType: 'groupChat',

                //是否在漫游里也删除该消息
                deleteRoam: true,
                error: err1 => {
                    console.log(err1, '删除失败1')
                },
                success: res1 => {
                    console.log(res1, '删除成功1')
                }
            }).then(res => {
                console.log('删除成功')
                this.$message.success('删除成功')
                conn.leaveGroup({
                    groupId: groupid,
                }).then(result1 => {
                    console.log(result1, '离开群组成功')
                    window.location.reload()
                }).catch(error1 => {
                    console.log(error1, '离开群组失败')
                    conn.destroyGroup({
                        groupId: groupid,
                    }).then(ress => {
                        console.log(ress, '解散群组成功')
                        window.location.reload()
                    }).catch(errr => {
                        console.log(errr, '解散群组失败')
                    })
                })

            }).catch(err => {
                console.log('删除失败')
            })
        },
        /**
         * 管理员账号删除联系人
         * @param item
         */
        deleteItem_admin(item) {
            console.log(item, '管理员账号删除联系人')
            // let {groupid} = item
            let uid = item.channel_id.split('@easemob.com')[0].split('cambojob_')[1]
            console.log(uid, '需要删除的uid')
            //删除的是单聊
            conn.deleteConversation({
                // channel: item.lastMessage.from,
                channel: item.uid || uid,
                chatType: 'singleChat',
                //是否在漫游里也删除该消息
                deleteRoam: true,
                error: error => {
                    console.log(error, '管理员删除账号失败')
                },
                success: res1 => {
                    console.log(res1, '管理员删除账号成功')
                }
            }).then(res => {
                console.log('删除成功')
                this.$message.success('删除成功')
                window.location.reload()
            }).catch(err => {
                console.log('删除失败')
            })
        },


        formatData(num) {
            return dayjs(num * 1000).format('YYYY/MM/DD HH:mm:ss');
        },
        formatInvite(msg) {
            const { interviewTime, type, address, contact } = msg.customExts;
            const t = new Date(interviewTime * 1000);
            const y = t.getFullYear();
            const M = t.getMonth() + 1 < 10 ? `0${t.getMonth() + 1}` : t.getMonth() + 1;
            const d = t.getDate() < 10 ? `0${t.getDate()}` : t.getDate();
            const h = t.getHours() < 10 ? `0${t.getHours()}` : t.getHours();
            const m = t.getMinutes() < 10 ? `0${t.getMinutes()}` : t.getMinutes();
            if (type === '1') {
                //视频面试
                return this.$t(msg.isLeft ? '收到线上面试' : '发起线上面试', { y, M, d, h, m, name: contact });
            } else {
                return this.$t(msg.isLeft ? '收到线下面试' : '发起线下面试', { y, M, d, h, m, name: contact, address });
            }
        },
        /**
         * 判断用户是否在线
         * @param id
         * @returns {boolean}
         */
        isOnline(id) {
            if (!id.includes('-')) {
                id = this.isBoss ? id + '-2' : id + '-1';
            }
            return typeof this.userStatus[id] === 'object';
        },
        isOnline_admin(id) {
            // if (!id.includes('-')) {
            //     id = this.isBoss ? id + '-2' : id + '-1';
            // }
            // return typeof this.userStatus[id] === 'object';
        },

        toBottom() {
            this.$nextTick(() => {
                const { msgList } = this.$refs;
                msgList && (msgList.scrollTop = msgList.scrollHeight);
            });
        },

        /**
         * 根据id获取用户头像
         * @param id
         * @returns {*}
         */
        getAvatarByID(id) {
            if (!id) return require('@/assets/img/CamboJob@2x.png');
            if (!id.includes('-')) {
                id = this.isBoss ? id + '-2' : id + '-1';
            }
            const { userInfoMap } = this;
            // console.log(userInfoMap, 'userInfoMapuserInfoMap', id)
            return (userInfoMap[id] && userInfoMap[id].avatar) || require('@/assets/img/CamboJob@2x.png');
        },
        getAvatarByID_admin(item) {
            console.log(item, 'itemitemitemitemitemitemitemitemitemitem')
            console.log(store.state.admin1.uid, 'store.state.admin1.uidstore.state.admin1.uid');
            let id = ''
            if (item.lastMessage.from == store.state.admin1.uid) {
                id = item.lastMessage.to
            } else {
                id = item.lastMessage.from
            }
            console.log(id, 'ididididididididididididididid')
            if (id.indexOf('g_') > -1) {
                return require('@/assets/img/CamboJob@2x.png');
            } else {
                postMemberAvatars([id]).then(res => {
                    console.log(res, 'postMemberAvatars(id)');
                    console.log(res[id]);
                    console.log(res[id].avatar);
                    if (res[id].avatar) {
                        console.log(res[id].avatar, 'res[id].avatar');
                        return res[id].avatar
                    } else {
                        return require('@/assets/img/CamboJob@2x.png');
                    }
                }).catch(err => {
                    console.log(err, 'postMemberAvatars(id)');
                    return require('@/assets/img/CamboJob@2x.png');
                })
            }
        },

        /**
         * 根据id获取用户名称
         * @param id
         * @returns {VueI18n.TranslateResult|string|string}
         */
        getNameByID(id) {
            // console.log(id, '根据id获取用户名')
            const { userInfoMap, isBoss, admin, currentGroup } = this;
            console.log(currentGroup, admin.uid, 'admin.uidadmin.uidadmin.uid')
            if (!id) {
                if (currentGroup === admin.uid) return this.$t('管理员');
                return '';
            }
            if ((admin.uid == '363440-1' || admin.uid == '2176-1'||admin.uid == '438121-1' || admin.uid == '399682-1') && store.state.me.user.indexOf('g_') > -1) {
                console.log('游客获取用户名')
                return this.$t('管理员')
            } else {
                // console.log('用户获取用户名2')
                if (id == '363440-1' || id == '2176-1'||id == '438121-1' || id == '399682-1') {
                    return this.$t('管理员')
                }
            }
            if (!id.includes('-')) {
                id = isBoss ? id + '-2' : id + '-1';
            }
            return userInfoMap[id] ? userInfoMap[id].nickname || '' : '未找到用户';
        },
        getNameByID_admin(item) {
            console.log(item, 'itemitemitemitemitemitemitemitemitemitem----')
            console.log(store.state.admin1.uid, 'store.state.admin1.uidstore.state.admin1.uid---');
            let id = ''
            if (item.lastMessage.from == store.state.admin1.uid) {
                id = item.lastMessage.to
            } else {
                id = item.lastMessage.from
            }
            console.log(id, 'ididididididididididididididid--------')
            if (id.indexOf('g_') > -1) {
                return '游客';
            } else {
                postMemberAvatars([id]).then(res => {
                    console.log(res, 'postMemberAvatars(id)---');
                    console.log(res[id]);
                    console.log(res[id].nickname);
                    if (res[id].avatar) {
                        console.log(res[id].nickname, 'res[id].avatar---');
                        return res[id].nickname
                    } else {
                        return '未找到用户';
                    }
                }).catch(err => {
                    console.log(err, '---postMemberAvatars(id)---');
                    return '未找到用户'
                })
            }
            console.log(this.showContactList_admin, 'showContactList_adminshowContactList_admin');
        },

        /**
         * 改变对话联系人
         * @param item
         */
        clickContact(item, index) {
            console.log(item, '非管理员切换的联系人', index, item.groupid)
            store.commit('updateOtherUserTarget', item.groupid)
            // currentGroup
            if (index == 0) {
                // let isClick = window.sessionStorage.getItem('isClick')
                let isClick = store.state.isClick;
                console.log('非管理员  选择  管理员')
                console.log(isClick, 'isClick')
                if (isClick) {
                    console.log('已经选择过一次了')
                    this.$store.dispatch('changeConversation', item.groupid || item);
                } else {
                    console.log('第一次选择')
                    this.$store.dispatch('changeConversation', item.groupid || item);
                }
                // conn.getHistoryMessages({
                //     chatType: 'singleChat',
                //     pageSize: 50,
                //     searchDirection: 'down',
                //     targetId: item.groupid
                // })
                //     .then(res => {
                //         console.log(res, '非管理员  选择  管理员  历史消息')
                //         let data = res.messages
                //         data.map(item => {
                //             if (item.type == 'text') {
                //                 item.type = 'chat';
                //                 item.contentsType = 'TEXT'
                //             }
                //             item.data = item.msg
                //             item.sourceMsg = item.msg
                //             if (item.from == store.state.admin1.uid) {
                //                 item.isLeft = true
                //             }
                //         })
                //         console.log(data, '非管理员  选择  管理员  历史消息  成功')
                //         // this.$store.state.groupMsg[item.groupid] = res
                //         // this.state.groupMsg[item.groupid] = res
                //         store.state.groupMsg[item.groupid] = res
                //     })
                //     .catch(err => {
                //         console.log(err, '非管理员  选择  管理员  历史消息  失败')
                //     })

                // this.$forceUpdate()
            } else {
                console.log('非管理员  选择  其他')
                this.$store.dispatch('changeConversation', item.groupid || item);
                // this.$forceUpdate()
            }

        },
        clickContact_admin(item, index) {
            this.listIndex = index
            this.showTarget = true
            console.log(item, '管理员更换对话联系人')
            item.avatar = this.showContactList_admin_avatar[this.listIndex].avatar
            item.nickname = this.showContactList_admin_avatar[this.listIndex].nickname
            window.sessionStorage.setItem('isAddMessage', 'true')
            store.commit('updateTarget_admin', item)
            getHistoryMessages_admin(item)
            console.log(store.state.showContactList_admin[index])
            store.state.showContactList_admin[index].unread_num = 0
            this.$forceUpdate()
            store.state.disableInput = false;
            console.log(store.state.showContactList_admin_avatar)
        },


        /**
         * 发送消息
         * @returns {Promise<void>}
         */
        async clickSendTextMsg() {
            console.log('发送消息')
            if (this.disableInput) return;
            this.text = this.text.replace(/^\s+|\s+$/g, "")
            if (this.text.length < 1) {
                console.log('不能发送空')
                return
            }
            const t = this.admin.uid === this.currentGroup ? await sendTextMsgToAdmin(this.text) : await sendTextMsg(this.currentGroup, this.text);
            if (t) this.text = '';
        },

        /**
         * 管理员发送文本消息
         */
        async clickSendTextMsg_admin() {
            console.log('发送消息')
            if (this.disableInput) return;
            this.text = this.text.replace(/^\s+|\s+$/g,"")
            if (this.text.length < 1) {
                console.log('不能发送空')
                return
            }
            sendTextMsg_admin(this.text)
            // if (t) this.text = '';
            this.text = ''
        },

        /**
         * 发送图片消息
         * @returns
         */
        async clickSendImgMsg() {
            if (this.disableInput) return;
            const t = this.admin.uid === this.currentGroup ? await sendImgMsgToAdmin(this.$refs.imgFile) : await sendImgMsg(this.currentGroup, this.$refs.imgFile);
            if (t) this.$refs.imgFile.value = '';
        },

        /**
         * 管理员发送图片消息
         * @returns
         */
        // todo:管理员发送图片信息
        async clickSendImgMsg_admin() {
            console.log('管理员发送图片消息');
            sendPrivateImg()
            function sendPrivateImg() {
                let obj = store.state.target_admin
                // 选择本地图片文件。
                let input = document.getElementById("imgFile");
                let file = WebIM.utils.getFileUrl(input);
                let allowType = {
                    jpg: true,
                    gif: true,
                    png: true,
                    bmp: true,
                };
                if (file.filetype.toLowerCase() in allowType) {
                    let option = {
                        // 设置消息类型。
                        type: "img",
                        file: file,
                        ext: {
                            // 设置图片文件长度，单位为字节。
                            file_length: file.data.size,
                        },
                        // 设置消息接收方的用户 ID。
                        to: obj.lastMessage.from == store.state.admin1.uid ? obj.lastMessage.to : obj.lastMessage.from,
                        // 设置会话类型。
                        chatType: "singleChat",
                        // 图片文件上传失败。
                        onFileUploadError: function () {
                            console.log("onFileUploadError");
                        },
                        // 图片文件上传进度。
                        onFileUploadProgress: function (e) {
                            console.log(e);
                        },
                        // 图片文件上传成功。
                        onFileUploadComplete: function () {
                            console.log("onFileUploadComplete");
                        },
                    };
                    // 创建一条图片消息。
                    let msg = WebIM.message.create(option);
                    // 调用 `send` 方法发送该图片消息。
                    conn
                        .send(msg)
                        .then((res) => {
                            // 图片文件成功发送。
                            console.log("Success");
                            getHistoryMessages_admin(obj)
                        })
                        .catch((e) => {
                            // 图片文件发送失败。
                            console.log("Fail");
                        });
                }
            }

        },

        /**
         * 检查是否有联系人或简历
         * @returns {Promise<boolean|*>}
         */
        async checkHasContactOrResume() {
            const { disableInput } = this;
            if (disableInput) return false;
            const { id: resume_id } = this.currentResume;
            const downloadPage = await getAjaxResumeGet(resume_id, this.groupUserMap[this.currentGroup].uid);
            const { applied, got } = downloadPage;
            this.$store.commit('updateState', { downloadPage });
            return applied || got;
        },

        /**
         * 单击“交换Tg模式”
         * @returns {Promise<boolean>}
         */
        async clickSwapTgModal() {
            if (this.isBoss && !(await this.checkHasContactOrResume())) return (this.getResumeModal = true);
            this.sendType = 'swap';
            this.tgModal = true;
            this.tg = getTg(this.me.uid) || this.me.telegram || '';
        },
        async clickSendSwapTgMsg() {
            if (!this.tg) return Message({ message: this.$t('id不能为空'), type: 'error' });
            if (this.onceClick) return;
            this.onceClick = true;
            setTg(this.me.uid, this.tg);
            console.log('send swap tg msg1');
            if (this.sendType === 'swap') {
                console.log('send swap tg msg2');
                const t = await sendSwapTgMsg(this.currentGroup, this.tg);
                console.log(t);
            } else if (this.sendType === 'accept') {
                console.log('send accept tg msg');
                const t = await sendTgMsg(this.currentGroup, this.tg, '1', this.deviceId, this.currentSwapMsg);
                console.log(t);
            }
            this.tgModal = false;
            // this.currentSwapMsg.disabled = true;
            this.onceClick = false;
        },

        //拒绝交换tg
        clickRejectSwap(msg) {
            console.log('拒绝')
            sendAcceptMsg(this.currentGroup, msg, '2');
        },

        //同意交换tg
        async clickSendAcceptSwapTgModal(msg) {
            console.log(msg)
            this.currentSwapMsg = msg;
            this.sendType = 'accept';
            this.tgModal = true;
            this.tg = getTg(this.me.uid) || this.me.telegram || '';
        },

        /**
         * 单击“打开面试模式”
         * @returns {Promise<boolean>}
         */
        async clickOpenInterviewModal() {
            console.log('发送面试邀请')
            if (!(await this.checkHasContactOrResume())) {
                return this.isBoss ? (this.interviewConfirmModal = true) : (this.confirmSendResumeModal = true);
            }
            if (this.isBoss) {
                this.interviewModal = true;
                const { id: resume_id } = this.currentResume;
                const { id: jobs_id, jobs_name } = this.currentJob;
                this.interviewForm.resume_id = resume_id;
                this.interviewForm.jobs_id = jobs_id;
                this.interviewForm.jobs_name = jobs_name;
            } else {
                sendApplyInterviewMsg(this.currentGroup);
            }
        },
        async clickSendApplyInterviewMsg() {
            await sendTextMsg(this.currentGroup, this.$t('你可以发一份简历给我吗?'));
            this.interviewConfirmModal = false;
        },

        /**
         * 单击“发送简历和面试”
         * @returns {Promise<void>}
         */
        async clickSendResumeAndInterview() {
            console.log('确认申请面试', this.currentResume)
            // 1. 简历卡片
            await this.clickSendResumeMsg(this.currentResume);
            // 2. 申请卡片
            await sendApplyInterviewMsg(this.currentGroup);
            this.confirmSendResumeModal = false;
        },

        clickToEditResume() {
            this.toEditResumeModal = false;
            window.open('/personal/resume_add.htm');
        },
        async clickInterviewConfirm() {
            const { time, jobs_name, title, ...data } = this.interviewForm;
            data.interview_time = (Number(time) / 1000) | 0;
            const res = await postInterviewAdd(data);
            console.log(res);
            if (!res) return;
            sendInviteInterviewMsg(this.currentGroup, res);
            this.interviewModal = false;
        },

        //企业端同意  或  拒绝求职端  accepted 1同意  2拒绝
        async clickAcceptEvent(msg, accepted) {
            const { customExts } = msg;
            if (customExts.disabled) return;
            customExts.disabled = true;
            const t = await sendAcceptMsg(this.currentGroup, msg, accepted);
            if (!t) return;
            this.$store.state.reloadIdx++;
            console.log(msg, accepted)
            console.log(this.currentResume, this.currentJob)
            if (accepted == 2) {
                // console.log('拒绝')
                // api.post('/home/chat/interview_status',{did:this.currentJob.id,status:2,reject_reason:""}).then(res=>{
                //   console.log(res,'点击拒绝')
                // }).catch(err=>{
                //   console.log(err)
                // })
            } else {
                this.clickOpenInterviewModal()
                // console.log('同意')
                // api.post('/home/chat/interview_status',{did:this.currentJob.id,status:1,reject_reason:""}).then(res=>{
                //   console.log(res,'点击同意')
                // }).catch(err=>{
                //   console.log(err)
                // })
            }
        },

        /**
         * 单击“发送恢复消息”
         * @param fullname
         * @param id
         * @returns {Promise<void>}
         */
        async clickSendResumeMsg({ fullname, id }) {
            console.log(this.currentGroup, fullname, id)
            const t = await sendResumeMsg(this.currentGroup, fullname, id);
            if (!t) return;
            this.sendResumeModal = false;
        },

        // 会话相关
        clickStartResumeContact(option) {
            this.coption = { uid: option.resume_uid, resume_id: option.resume_id };
            this.jobsModal = true;
        },
        async clickSelectJob({ id: jobsId, jobs_name: jobName }) {
            await beginConversation({
                jobName,
                jobsId,
                ...this.coption,
            });
            this.jobsModal = false;
        },
        async clickStartJobContact(option) {
            this.coption = { uid: option.company_id, jobsId: option.jobsid, jobName: option.jobs_name };
            if (this.onlineResumeList.length === 0) {
                this.toEditResumeModal = true;
            } else if (this.onlineResumeList.length === 1) {
                this.clickSelectResume(this.onlineResumeList[0]);
                this.clickConfirmResume();
            } else {
                this.resumeModal = true;
            }
        },
        clickSelectResume({ id: resume_id }) {
            this.$set(this.coption, 'resume_id', resume_id);
        },
        async clickConfirmResume() {
            if (!this.coption.resume_id) return Message({ message: this.$t('请选择简历'), type: 'error' });
            await beginConversation(this.coption);
            this.resumeModal = false;
        }, //
        async clickSendInterviewVideoMsg() {
            if (!(await this.checkHasContactOrResume())) return (this.getResumeModal = true);
            const t = await sendInviteVideoMsg(this.currentGroup);
            console.log(t);
        },
        async clickReceiveInterviewCall(action) {
            const { ext, groupid } = this.videoData;
            sendAnswerCallMsg(groupid, ext, action);
            if (action === 'accept') window.open('/chatapp/video?v=' + (this.cameraStatus ? '1' : ''), 'call');
            this.$store.commit('updateState', { hasVideoCall: false });
        },
        async clickCenter(e) {
            if (e.target.id === 'contact') {
                this.getContactModal();
            }
        },

        /**
         * 获取联系人模式
         * @param modal
         * @returns {Promise<void>}
         */
        async getContactModal(modal) {
            const { currentResume, me } = this;
            const data = await getResumeDownload(currentResume.id, me.uid);
            this.hasDialog = true;
            this[modal] = false;
            if (typeof data === 'string') {
                $('#pay').html(data);
            }
        },
        formatYears(year) {
            return new Date().getFullYear() - year;
        },
        onCopy() {
            Message({ message: this.$t('复制成功'), type: 'success' });
        },
    },
    async mounted() {
        let that = this


        console.log('修复聊天bug-111 联系人测试48')
        console.log('测试删除5')
        console.log('测试会话列表38')
        console.log('测试删除联系人007')
        await initIM(true);
        this.tg = getTg(this.me.uid) || '';
        const { isBoss } = this.$store.state;

        //如果是企业端
        if (isBoss) {
            console.log('这是企业端')
            postResumeViewLog().then(async ({ list = [] }) => {
                if (list.length > 0) {
                    await this.$store.dispatch(
                        'subscribeUsers',
                        list.map(v => v.resume_uid)
                    );
                    for (let i = 0; i < list.length; i++) {
                        const { resume_uid } = list[i];
                        list[i].onlineStatus = this.isOnline(resume_uid);
                    }
                    list = list.sort((a, b) => {
                        return a.onlineStatus ? -1 : 1;
                    });
                    this.historyList = list;
                }
            });
            getJobLists().then(res => {
                this.$store.commit('updateState', { jobList: res });
            });
        }
        //如果是求职端
        else {
            console.log('这是求职端')
            postJobViewLog().then(({ list = [] }) => {
                this.$store.dispatch(
                    'subscribeUsers',
                    list.map(v => v.company_id)
                );
                this.historyList = list;
            });
            getResumeLists().then(res => {
                console.log(res, '自己的简历列表')
                this.$store.commit('updateState', { resumeList: res });
            });

            getOnlineResumeLists().then(res => {
                console.log(res, '获取在线简历列表')
                this.onlineResumeList = res
            });
        }

        //判断用户是否是游客，如果是游客，则显示选择弹窗
        let me = store.state.me

        // if (store.state.me.nickname == '游客' || store.state.me.nickname == 'Guest' || store.state.me.nickname == 'ភ្ញៀវ') {
        if (store.state.me.user.indexOf('g_') > -1) {
            console.log('判断用户是否是游客，如果是游客，则显示选择弹窗')
            if (window.localStorage.getItem('selectRule')) {
                console.log('已经选择过')
                this.show = true
                this.showMain = true
            } else {
                console.log('没有选择过')
                this.show = true
                this.showMain = false
            }
        } else {
            console.log('不是游客')
            this.show = true
            this.showMain = true
        }
    },
};

function getTg(uid) {
    let tg = $get('tg');
    if (typeof tg !== 'object') {
        tg = {};
        $set('tg', tg);
        return '';
    }
    return tg[uid] || '';
}

function setTg(uid, _tg) {
    let tg = $get('tg');
    if (typeof tg !== 'object') {
        tg = {};
    }
    tg[uid] = _tg;
    $set('tg', tg);
}
