export default {
  lang: 'kh',

  面试邀请: 'ការអញ្ជើញសម្ភាសន៍',
  查看简历: 'មើលប្រវត្តិរូបសង្ខេប',
  发送Telegram号: 'ផ្ញើ Telegram',
  请输入您的Telegram号: 'សូម​បញ្ចូល Telegram',
  请选择面试方式: 'សូមជ្រើសរើសវិធីសម្ភាសន៍',
  线下面试: 'សំភាសន៍ក្រៅបណ្តាញ',
  视频面试: 'វីដេអូសំភាសន៍',
  请输入面试地点: 'សូមបញ្ចូលទីតាំងសម្ភាសន៍',
  请输入备注说明: 'សូមបញ្ចូលការពិពណ៌នាចំណាំ',
  请选择简历: 'សូមជ្រើសរើសប្រវត្តិរូបសង្ខេប',
  取消: 'បោះបង់',
  投递简历成功: 'ប្រគល់ប្រវត្តិរូបដោយជោគជ័យ',
  接受: 'ទទួលយក',
  备注说明: 'កំណត់សំគាល់',
  确定: 'កំណត់',
  拒绝: 'បដិសេធ',
  同意: 'យល់ព្រម',
  面试时间修改: 'ការកែប្រែពេលវេលាសម្ភាសន៍',
  的Telegram账号: 'Telegram',
  复制账号: 'ចម្លងគណនី',
  复制成功: 'ចម្លងដោយជោគជ័យ',
  的简历: 'ប្រវត្តិរូបសង្ខេប',
  发送: 'ផ្ញើ',
  // 提醒
  未连接请稍后再试: 'មិនត្រូវបានតភ្ជាប់ទេសូមព្យាយាមម្តងទៀតនៅពេលក្រោយ',
  发送失败: 'ការ​ផ្ញើ​រ​ចេញ​មិនបាន​សំរេច',
  格式不支持: 'មិនគាំទ្រទ្រង់ទ្រាយ',
  您向对方发送了互换Telegram请求: 'អ្នកបានផ្ញើសំណើផ្លាស់ប្តូរតេឡេក្រាមទៅឱ្យភាគីម្ខាងទៀត',
  已发送Telegram号: 'ផ្ញើលេខតេឡេក្រាម',
  您向对方发送了面试申请: 'អ្នកបានផ្ញើពាក្យសុំសម្ភាសន៍ទៅឱ្យភាគីម្ខាងទៀត',
  您向对方发送了面试邀请: 'អ្នកបានផ្ញើលិខិតអញ្ជើញសម្ភាសន៍ទៅភាគីម្ខាងទៀត',
  您已同意面试邀请: 'អ្នកបានយល់ព្រមចំពោះការអញ្ជើញសម្ភាសន៍',
  你已拒绝视频面试邀请: 'អ្នកបានបដិសេធការអញ្ជើញទៅសម្ភាសន៍',
  您已接受对方修改面试时间: 'អ្នកបានទទួលយកភាគីម្ខាងទៀតដើម្បីកែប្រែពេលវេលាសំភាសន៍',
  你已拒绝对方修改面试时间: 'អ្នកបានបដិសេធមិនផ្លាស់ប្តូរពេលវេលាសំភាសន៍',
  '[简历]': 'ប្រវត្តិរូបសង្ខេប',
  '[互换Telegram]': '[ការផ្លាស់ប្តូរTelegram]',
  '[申请面试]': '[ដាក់ពាក្យសុំសំភាសន៍]',
  '[邀请面试]': '[ការអញ្ជើញសម្ភាសន៍]',
  '[修改面试时间]': '[កែប្រែពេលវេលាសម្ភាសន៍]',
  对方已同意您的面试邀请: 'ភាគីម្ខាងទៀតបានយល់ព្រមចំពោះការអញ្ជើញសម្ភាសន៍របស់អ្នក',
  对方拒绝了您的面试邀请: 'ភាគីម្ខាងទៀតបានបដិសេធការអញ្ជើញសម្ភាសន៍របស់អ្នក',
  对方已同意修改面试时间: 'ភាគីម្ខាងទៀតបានយល់ព្រមកែប្រែពេលវេលាសំភាសន៍',
  对方拒绝了修改面试时间: 'ភាគីម្ខាងទៀតបដិសេធមិនកែប្រែពេលវេលាសម្ភាសន៍',
  // 视频部分
  岁: 'អាយុ',
  您发起了视频邀请: 'បានផ្ញើការអញ្ជើញជាវីដេអូ',
  对方已拒绝: 'ភាគីម្ខាងទៀតបានបដិសេធ',
  'id 不能为空': 'idមិនអាចគ្មានទេ',
  '你可以发一份简历给我吗?': 'តើអ្នកអាចផ្ញើប្រវត្តិរូបសង្ខេបមកខ្ញុំបានទេ?',
  你需要先获取对方的资料: 'អ្នកត្រូវទទួលបានព័ត៌មានពីភាគីម្ខាងទៀតជាមុនសិន',
  马上获取: 'ទទួលបានវាឥឡូវនេះ',
  '申请面试将默认发送您的简历给对方!': 'ការដាក់ពាក្យសម្ភាសន៍នឹងផ្ញើប្រវត្តិរូបសង្ខេបរបស់អ្នកទៅភាគីម្ខាងទៀតតាមលំនាំដើម!',
  确认: 'បញ្ជាក់',
  您已获取对方的联系方式: 'អ្នកបានទទួលព័ត៌មានទំនាក់ទំនងរបស់ភាគីម្ខាងទៀត',
  对方已获取您的联系方式: 'ភាគីម្ខាងទៀតបានទទួលព័ត៌មានទំនាក់ទំនងរបស់អ្នក',
  请先完成引导: 'សូមបំពេញការណែនាំជាមុនសិន',
  正在咨询: 'កំពុងប្រឹក្សា',

  对方已同意您的面试申请: 'ភាគីម្ខាងទៀតបាន <span class="green">យល់ព្រម</span> ពាក្យសុំសម្ភាសន៍របស់អ្នក',
  对方已拒绝您的面试申请: 'ភាគីម្ខាងទៀតបាន <span class="red">បានបដិសេធ</span> ពាក្យសុំសម្ភាសន៍របស់អ្នក',

  '[视频面试邀请]': '[ការអញ្ជើញសម្ភាសន៍ជាវីដេអូ]',
  '[联系方式]': '[ព័ត៌មានទំនាក់ទំនង]',
  '[语音消息]': '[សារជាសំឡេង]',

  您已同意对方面试申请: 'អ្នកបានយល់ព្រមលើពាក្យសុំសម្ភាសន៍របស់ភាគីម្ខាងទៀត',
  您已拒绝对方面试申请: 'អ្នកបានបដិសេធពាក្យសុំសម្ភាសន៍របស់ភាគីម្ខាងទៀត',
  登录: 'ចុះឈ្មោះចូល',

  对方向您发送了互换Telegram请求: 'ភាគីម្ខាងទៀតបានផ្ញើសំណើផ្លាស់ប្តូរតេឡេក្រាមមកអ្នក',
  您已拒绝与对方互换Telegram: 'អ្នកបានបដិសេធមិនផ្លាស់ប្តូរតេឡេក្រាមជាមួយភាគីម្ខាងទៀត',
  对方已拒绝与您互换Telegram: 'ភាគីម្ខាងទៀតបានបដិសេធមិនផ្លាស់ប្តូរតេឡេក្រាមជាមួយអ្នក',
  已拒绝: 'ត្រូវបានបដិសេធ',
  已同意: 'បានយល់ព្រម',

  管理员: 'អ្នកគ្រប់គ្រង',
  对方无应答: 'ភាគីម្ខាងទៀតមិនយល់ព្រម',
  对方已取消: 'ភាគីម្ខាងទៀតបានរំសាយ',
  已取消: 'បានរំសាយ',
  呼叫超时: 'អស់ពេលហៅទូរសព្ទ',
  通话时长: 'រយៈពេលហៅទូរសព្ទ {រយៈពេល}',
  '提示: 只有当对方回复后, 才能继续聊天': 'ព័ត៌មានបញ្ជាក់:ភាគីម្ខាងទៀតឆ្លើយតបហើយទើបអាចបន្តជជែក',
  '或者您也可以:': 'ឬអ្នកក៏អាច៖',
  获取对方的联系方式: 'ទទួលបានព័ត៌មានទំនាក់ទំនងរបស់ភាគីម្ខាងទៀត',
  id不能为空: 'លេខសម្គាល់មិនអាចគ្មាន',

  搜索会话: 'ស្វែងរកការសន្ទនា',
  删除会话: 'លុបចោលសារ',
  在这里输入: 'បញ្ចូលនៅទីនេះ',
  查看详情: 'ពិនិត្យព័ត៌មានលម្អិត',

  姓名: 'ឈ្មោះ',
  微信: 'វីឆាត',
  手机: 'ទូរស័ព្ទ',
  邮箱: 'អុីម៉ែល',

  我的Telegram为: 'តេឡេក្រាមរបស់អ្នកគឺ',
  // 面试

  // 预约面试
  面试主题: 'ប្រធានបទសម្ភាសន៍',
  请输入面试主题: 'សូមបញ្ចូលប្រធានបទការសម្ភាសន៍',
  面试时间: 'ពេលវេលាសម្ភាសន៍',
  面试方式: 'វិធីសាស្រ្តសម្ភាសន៍',
  面试地点: 'ទីតាំងសម្ភាសន៍',
  选择日期时间: 'ជ្រើសរើសកាលបរិច្ឆេទនៃការសម្ភាសន៍',
  // 沟通职位
  请选择要沟通的职位: 'សូមជ្រើសរើសមុខតំណែងដើម្បីទំនាក់ទំនង',

  // 选择简历
  选择简历: 'ជ្រើសរើសប្រវត្តិរូបសង្ខេប',
  更新: 'អោយឡើងថ្មី',
  完成度: 'បានពេញលេញ',

  // 视频通话
  视频邀请: 'ការអញ្ជើញវីដេអូ',
  工作面试: 'សម្ភាសន៍ការងារ',
  我: 'ខ្ញុំ',
  对方未开启摄像头: 'ភាគីម្ខាងទៀតមិនបានបើកកាមេរ៉ាទេ',
  视频通话结束: 'ការហៅជាវីដេអូបានបញ្ចប់',
  企业中心: 'មជ្ឈមណ្ឌលសហគ្រាស',

  // 面试评价
  面试评价: 'ការវាយតម្លៃការសម្ភាសន៍',
  你觉得面试怎么样: 'តើអ្នកមានអារម្មណ៍យ៉ាងណាចំពោះការសម្ភាសន៍',
  评价成功: 'ការវាយតម្លៃបានជោគជ័យ',
  '我们已经收到您的反馈, 感谢您的耐心填写, 期待您找到好工作': 'យើងបានទទួលមតិកែលម្អរបស់អ្នក អរគុណសម្រាប់ការអត់ធ្មត់របស់អ្នក ហើយសង្ឃឹមថាអ្នកនឹងរកបានការងារល្អ',
  返回首页: 'ត្រលប់ទៅទំព័រដើម',
  返回个人中心: 'ត្រឡប់ទៅមជ្ឈមណ្ឌលអ្នកប្រើប្រាស់',
  保存: 'រក្សាទុក',
  关闭: 'បិទ',

  预览: 'ការមើលជាមុន',
  邀请您面试: '{ទំនាក់ទំនង}អ្នកត្រូវបានអញ្ជើញឱ្យមកសម្ភាសន៍',
  您邀请面试: 'អ្នកអញ្ជើញ {ឈ្មៅះ} {ប្រភេទ} ទៅសម្ភាសន៍',
  视频: 'វីដេអូ',
  线下: 'នៅក្រៅបណ្តាញ',
  '您好, 请问可以在线视频面试吗 ?': 'សួស្តី តើខ្ញុំអាចសម្ភាសន៍វីដេអូតាមអនឡាញបានទេ?',
  默认: 'លំនាំដើម',
  简介: 'សេចក្តីផ្តើមណែនាំ',
  历史记录: 'កំណត់ត្រា',
  薪资: 'ប្រាក់ខែ',
  工作经验: 'បទពិសោធន៍ការងារ',
  学历: 'ការអប់រំ',
  语言: 'ភាសា',
  工作内容: 'មាតិកាការងារ',
  暂无工作经验: 'មិនទាន់មានបទពិសោធន៍ការងារ',
  自我评价: 'ការវាយតម្លៃលើ​ខ្លួនឯង',
  在线: 'អនឡាញ',
  月: 'ខែ',
  咨询: 'ប្រឹក្សា',

  // 补充
  暂无简历: 'ភាគីម្ខាងទៀតបានបិទ',
  通话已挂断: 'សន្ទនាបានបញ្ចប់',
  您已同意对方的面试邀请: 'អ្នកបាន<b class="green">យល់ព្រម</b>ការអញ្ជើញសម្ភាសន័៍របស់ភាគីម្ខាងទៀត, ពេកវេលានៃការសម្ភាសន៍នៅសល់ {d}ថ្ងៃ{h}ម៉ោង',
  您已拒绝对方的面试邀请: 'អ្នកបាន<b class="red">បដិសេធ</b>ការអញ្ជើញសម្ភាសន័៍សម្ភាសន័៍របស់ភាគីម្ខាងទៀត',
  新消息提醒: 'មានព័ត៌មានថ្មី',

  我想要申请一次面试机会: 'ខ្ញុំចង់ដាក់ពាក្យសម្ភាសន៍',
  请先取消隐藏在线简历: 'សូមឈប់លាក់ប្រវត្តិរូបសង្ខេបតាមអ៊ីនធឺណិតជាមុនសិន',
  对方操作后会有提示回执: 'ភាគីម្ខាងទៀតនឹងបញ្ជាក់ក្រោយធ្វើប្រតិបត្តិការហើយ',
  收到线上面试: '<span class="green">{name}</span>，យើងខ្ញុំសូមអញ្ជើញអ្នកចូលរួមសម្ភាស៍វីដេអូខលនៅថ្ងៃទី <span class="green">{d}.{M}.{y} វេលាម៉ោង {h}:{m}</span> ។',
  收到线下面试: '<span class="green">{name}</span>，អញ្ជើញអ្នកធ្វើការសម្ភាសន៍នៅ <span class="green">{h}:{m} នៅ {d}.{M}.{Y}</span> នៅ <span class="green">{address}</span>, តើអ្នកទទួលយកវាទេ?',

  发起线上面试: 'អ្នកត្រូវបានអញ្ជើញ " <span class="green">{name}</span> " សម្រាប់ការសម្ភាសន៍តាមវីដេអូខលនៅក្នុង<span class="green">{y}.{M}.{d} {h}:{ m</span>',
  发起线下面试: 'អ្នកត្រូវបានអញ្ជើញ "<span class="green">{name}</span>" សម្រាប់ការសម្ភាសន៍ផ្ទាល់ <span class="green">{y}.{M}.{d} {h}:{m} </span> ទីតាំង នៅ<span class="green">{address}</span>',
  您还没有编辑在线简历: 'អ្នកមិនទាន់បានបញ្ចប់ប្រវត្តិរូបបច្ចប្បន្នរបស់អ្នកទេ',
  知道了: 'ពេលក្រោយ',
  去编辑: 'បញ្ចប់ឥឡូវនេះ',
  联系人: 'អ្នកទំនាក់ទំនង',
  联系电话: 'លេខទំនាក់​ទំនង',

  请选择身份: 'ជ្រើសរើសអត្តសញ្ញាណរបស់អ្នក',
  我是企业: 'ក្រុមហ៊ុន',
  我是求职者: 'អ្នកស្វែងរកការងារ',
};
