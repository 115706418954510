import {conn} from '@/assets/js/easemob/init';
import store from '@/store';
import agoraApi from './agora';
import {api} from './http';

// 获取环信密码
export const getMemberPwd = () => api.get(`/Chat/member_pwd`);

// 获取用户信息
export const getMemberBase = uid => api.get(`/Chat/member_base/uid/${uid}`);

// 批量获取头像昵称
export const postMemberAvatars = data => api.post(`/Chat/member_avatars`, {uid: data});

// 企业端邀请面试
export const postInterviewAdd = data => api.post(`/Chat/interview_add`, data);

// 职位/简历浏览列表
export const postResumeViewLog = () => api.post(`/Home/Im/resume_viewlog.htm`);

// 职位/简历浏览列表
export const postJobViewLog = () => api.post(`/Home/Im/job_viewlog.htm`);

/**
 * 发布获取简历
 * uid/ resume_id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postGetResume = data => api.post(`/Home/Im/get_resume.htm`, data);

// 职位详情 uid/ job_id
export const postGetJob = data => api.post(`/Home/Im/get_job.htm`, data);

// 获取自己的职位列表
export const getJobLists = () => api.get(`/Home/Im/job_lists.htm`);

// 获取自己的简历列表
export const getResumeLists = () => api.get(`/Home/Im/resume_lists.htm`);

/**
 * 获取在线简历列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOnlineResumeLists = () => api.get(`/Home/Im/online_resume_lists.htm`);

// 其他页面打开聊天页面, 传入 token 获取初始化聊天窗口信息
export const postAjaxGetTokenInfo = token => api.post(`/Home/Im/ajax_get_token_info.htm`, {token});

// 面试评价
// {"job_id": '', "picture":["http://xxxx.jpeg", "http://xxxx.jpeg"], "content":"xxxxxxx", "score": 4}
export const postAjaxEvaluate = data => api.post(`/Home/Im/ajax_evaluate.htm`, data);

// 面试备注
// {"job_id": '', "interview_note":"xxxxxxx"}
export const postAjaxInterviewNote = data => api.post(`/Home/Im/ajax_interview_note.htm`, data);

// 获取声网 token
// {userAccount: '', channelName: '', appkey: ''}
// export const getAgoraToken = async channelName => {
//     const {accessToken, userId} = conn.context;
//     const {data} = await agoraApi.get(
//         `/token/rtcToken/v1`,
//         {
//             params: {
//                 channelName,
//                 userAccount: userId,
//                 appkey: store.state.options.appid,
//             },
//         },
//         {
//             headers: {
//                 Authorization: 'Bearer ' + accessToken,
//             },
//         }
//     );
//     return data;
// };

// 获取声网 token
// {"interface":"/user/easemob-im/rtc-token", "params":{"channelName": "xxxxx", "uid": 363912}}
export const getAgoraToken = async channelName => {
    const {userId} = conn.context;
    let uid = userId
    if (userId.split('-').length > 0) {
        uid = userId.split('-')[0]
    }
    const data = {
        interface: "/user/easemob-im/rtc-token",
        params:  {channelName, uid}
    }
    const res = await api.post(`/api/request`, data);
    return {accessToken: res.token, agoraUserId: uid};
};

/**
 * 上传后附加
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postUploadAttach = file => {
    const formData = new FormData();
    formData.append('interview_img', file);
    formData.append('type', 'interview_img');
    return api.post(`/Home/Upload/attach.htm`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const headers = {
    accept: 'text/html,application/xhtml+xml,application/xml;',
};

/**
 * 获取简历下载
 * @param rid
 * @param uid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getResumeDownload = (rid, uid) => api.get(`/CompanyService/resume_download${rid ? `/rid/${rid}` : ''}/uid/${uid}/im/1.htm`, {headers: {'x-requested-with': 'XMLHttpRequest'}});

/**
 * 获取Ajax简历获取
 * @param rid
 * @param uid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAjaxResumeGet = (rid, uid) =>
    api.post(
        `/im/ajax_resume_get`,
        {uid, rid},
        {
            headers,
        }
    );
